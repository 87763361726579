export const HTTP_CODE_REF = [
  // {
  //   name: '1xx: Information',
  //   color: 'blue',
  //   codes: [
  //     {
  //       label: '100 Continue',
  //       description:
  //         'Only a part of the request has been received by the server, but as long as it has not been rejected, the client should continue with the request.',
  //     },
  //     {
  //       label: '101 Switching Protocols',
  //       description: 'The server switches protocol.',
  //     },
  //   ],
  // },
  {
    name: '2xx: Successful',
    color: 'green',
    codes: [
      {
        label: '200 OK',
        description: 'The request is OK.',
      },
      // {
      //   label: '201 Created',
      //   description: 'The request is complete, and a new resource is created .',
      // },
      // {
      //   label: '202 Accepted',
      //   description: 'The request is accepted for processing, but the processing is not complete.',
      // },
      // {
      //   label: '203 Non-authoritative Information',
      //   description:
      //     'The information in the entity header is from a local or third-party copy, not from the original server.',
      // },
      {
        label: '204 No Content',
        description:
          'A status code and a header are given in the response, but there is no entity-body in the reply.',
      },
      // {
      //   label: '205 Reset Content',
      //   description:
      //     'The browser should clear the form used for this transaction for additional input.',
      // },
      // {
      //   label: '206 Partial Content',
      //   description:
      //     'The server is returning partial data of the size requested. Used in response to a request specifying a Range header. The server must specify the range included in the response with the Content-Range header.',
      // },
    ],
  },
  // {
  //   name: '3xx: Redirection',
  //   color: 'yellow',
  //   codes: [
  //     {
  //       label: '300 Multiple Choices',
  //       description:
  //         'A link list. The user can select a link and go to that location. Maximum five addresses  .',
  //     },
  //     {
  //       label: '301 Moved Permanently',
  //       description: 'The requested page has moved to a new url .',
  //     },
  //     {
  //       label: '302 Found',
  //       description: 'The requested page has moved temporarily to a new url .',
  //     },
  //     {
  //       label: '303 See Other',
  //       description: 'The requested page can be found under a different url .',
  //     },
  //     {
  //       label: '304 Not Modified',
  //       description:
  //         'This is the response code to an If-Modified-Since or If-None-Match header, where the URL has not been modified since the specified date.',
  //     },
  //     {
  //       label: '305 Use Proxy',
  //       description:
  //         'The requested URL must be accessed through the proxy mentioned in the Location header.',
  //     },
  //     {
  //       label: '306 Unused',
  //       description:
  //         'This code was used in a previous version. It is no longer used, but the code is reserved.',
  //     },
  //     {
  //       label: '307 Temporary Redirect',
  //       description: 'The requested page has moved temporarily to a new url.',
  //     },
  //   ],
  // },
  {
    name: '4xx: Client Error',
    color: 'red',
    codes: [
      {
        label: '400 Bad Request',
        description: 'The server did not understand the request.',
      },
      {
        label: '401 Unauthorized',
        description: 'The requested page needs a username and a password.',
      },
      // {
      //   label: '402 Payment Required',
      //   description: 'You can not use this code yet.',
      // },
      {
        label: '403 Forbidden',
        description: 'Access is forbidden to the requested page.',
      },
      {
        label: '404 Not Found',
        description: 'The server can not find the requested page.',
      },
      // {
      //   label: '405 Method Not Allowed',
      //   description: 'The method specified in the request is not allowed.',
      // },
      // {
      //   label: '406 Not Acceptable',
      //   description: 'The server can only generate a response that is not accepted by the client.',
      // },
      // {
      //   label: '407 Proxy Authentication Required',
      //   description: 'You must authenticate with a proxy server before this request can be served.',
      // },
      {
        label: '408 Request Timeout',
        description: 'The request took longer than the server was prepared to wait.',
      },
      // {
      //   label: '409 Conflict',
      //   description: 'The request could not be completed because of a conflict.',
      // },
      // {
      //   label: '410 Gone',
      //   description: 'The requested page is no longer available .',
      // },
      // {
      //   label: '411 Length Required	',
      //   description:
      //     'The "Content-Length" is not defined. The server will not accept the request without it .',
      // },
      // {
      //   label: '412 Precondition Failed',
      //   description: 'The pre condition given in the request evaluated to false by the server.',
      // },
      // {
      //   label: '413 Request Entity Too Large',
      //   description:
      //     'The server will not accept the request, because the request entity is too large.',
      // },
      // {
      //   label: '414 Request-url Too Long',
      //   description:
      //     'The server will not accept the request, because the url is too long. Occurs when you convert a "post" request to a "get" request with a long query information .',
      // },
      // {
      //   label: '415 Unsupported Media Type',
      //   description:
      //     'The server will not accept the request, because the mediatype is not supported .',
      // },
      // {
      //   label: '416 Requested Range Not Satisfiable',
      //   description: 'The requested byte range is not available and is out of bounds.',
      // },
      // {
      //   label: '417 Expectation Failed',
      //   description:
      //     'The expectation given in an Expect request-header field could not be met by this server.',
      // },
      {
        label: '429 Too Many Requests',
        description:
          'The HTTP 429 Too Many Requests response status code indicates the user has sent too many requests in a given amount of time ("rate limiting").',
      },
    ],
  },
  {
    name: '5xx: Server Error',
    color: 'red',
    codes: [
      {
        label: '500 Internal Server Error',
        description: 'The request was not completed. The server met an unexpected condition.',
      },
      // {
      //   label: '501 Not Implemented',
      //   description:
      //     'The request was not completed. The server did not support the functionality required.',
      // },
      {
        label: '502 Bad Gateway',
        description:
          'The request was not completed. The server received an invalid response from the upstream server.',
      },
      {
        label: '503 Service Unavailable',
        description:
          'The request was not completed. The server is temporarily overloading or down.',
      },
      {
        label: '504 Gateway Timeout',
        description: 'The gateway has timed out.',
      },
      // {
      //   label: '505 HTTP Version Not Supported',
      //   description: 'The server does not support the "http protocol" version.',
      // },
    ],
  },
];
