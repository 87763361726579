export const LEGEND = [
  {
    name: 'Poor',
    color: 'red',
    range: '0 - 49',
    value: 0,
  },
  {
    name: 'Needs improvement',
    color: 'yellow',
    range: '50 - 89',
    value: 0.5,
  },
  {
    name: 'Good',
    color: 'green',
    range: '90 - 100',
    value: 0.9,
  },
];
