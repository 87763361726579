import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REPORTS } from '../../../modules/constants';

type ReportStatusType = {
  [REPORTS.BASIC]: any;
  [REPORTS.ADVANCED]: any;
  [REPORTS.BACKEND_API]: any;
};

const initialState: ReportStatusType = {
  [REPORTS.BASIC]: null,
  [REPORTS.ADVANCED]: null,
  [REPORTS.BACKEND_API]: null,
};

const reportSlice = createSlice({
  name: 'reportStatus',
  initialState,
  reducers: {
    setStatusResponse: (state: any, action: PayloadAction<any>) => {
      const { reportStatus, reportType } = action.payload;
      state[reportType] = reportStatus;
    },
    resetStatusResponse: (state: any) => {
      state = {
        [REPORTS.BASIC]: null,
        [REPORTS.ADVANCED]: null,
        [REPORTS.BACKEND_API]: null,
      };
    },
  },
});

export const { setStatusResponse, resetStatusResponse } = reportSlice.actions;
export default reportSlice.reducer;
