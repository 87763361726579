import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { getProgressBarColorObject } from '../../modules/Utils/utils';
import emptyLogo from '../../assets/images/empty_logo.jpeg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getStackIcon } from './HistoryTableUtil';
import FullDonut from '../common/FullDonut';
import styles from './style.module.css';

type TProps = {
  data: any[];
};
const DesktopTableComponent = (props: TProps) => {
  const { data } = props;
  return (
    <TableContainer>
      <Table className={styles.table} aria-label="history table">
        <TableHead className={styles.table__head}>
          <TableRow>
            <TableCell className={styles.table__head_cell} rowSpan={2} width={'10%'}>
              Website Url
            </TableCell>
            <TableCell className={styles.table__head_cell} rowSpan={2}>
              Software Used
            </TableCell>
            <TableCell className={styles.table__head_cell} rowSpan={2}>
              Performance Index
            </TableCell>
            <TableCell className={styles.table__head_cell} colSpan={5}>
              Metrics
            </TableCell>
            <TableCell className={styles.table__head_cell} rowSpan={2}>
              Details
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.table__head_cell}>TBT</TableCell>
            <TableCell className={styles.table__head_cell}>LCP</TableCell>
            <TableCell className={styles.table__head_cell}>CLS</TableCell>
            <TableCell className={styles.table__head_cell}>FCP</TableCell>
            <TableCell className={styles.table__head_cell}>SI</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any) => {
            const performanceColor = getProgressBarColorObject(item.performanceScore);
            return (
              <a
                className={styles.table_body_row}
                href={'https://app.roadrunnerhq.com/site-history/' + item.taskStatus.taskGroupId}
                target="blank"
                role="row"
              >
                <TableCell className={styles.table_body_url_cell}>
                  <div className={styles.table_body_url_cell_div}>
                    <img
                      src={item.iconUrl ?? emptyLogo}
                      style={{ width: '25px', height: '25px' }}
                      onError={(e: any) => {
                        e.target.src = emptyLogo;
                      }}
                      alt="logo"
                    />
                    <div className={styles.url_text}>{item.url}</div>
                  </div>
                </TableCell>
                <TableCell className={styles.table_body_general_cell}>
                  <img src={getStackIcon(item.stack)} height={20} alt={item.stack} />
                </TableCell>
                <TableCell className={styles.table_body_general_cell}>
                  <div className={styles.chart__container}>
                    <FullDonut
                      value={item.performanceScore * 100}
                      baseColor={performanceColor.baseColor}
                      barColor={performanceColor.barColor}
                    />
                  </div>
                </TableCell>
                {[
                  { id: 'TBT', score: item.tbt },
                  { id: 'LCP', score: item.lcp },
                  { id: 'CLS', score: item.cls },
                  { id: 'FCP', score: item.fcp },
                  { id: 'SI', score: item.si },
                ].map((audit: any) => {
                  const metricColor = getProgressBarColorObject(audit.score);
                  return (
                    <TableCell className={styles.table_body_general_cell}>
                      <div className={styles.chart__container}>
                        <FullDonut
                          value={audit.score * 100}
                          baseColor={metricColor.baseColor}
                          barColor={metricColor.barColor}
                        />
                      </div>
                    </TableCell>
                  );
                })}
                <TableCell className={styles.table_body_general_cell}>
                  <MoreVertIcon sx={{ color: '#392d69' }} />
                </TableCell>
              </a>
            );
          })}
          <TableRow>
            <TableCell colSpan={9}>
              <div className={styles.footer_message}>
                Displaying the latest webshop performance reports
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DesktopTableComponent;
