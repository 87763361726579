import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import styles from './errorreport.module.css';

type TProps = {
  message?: string;
};
export const ErrorReport = (props: TProps) => {
  const { message } = props;
  return (
    <div className={styles.error__container}>
      <ErrorOutlineOutlinedIcon className={styles.error__icon} />
      <p> {message ?? 'Something went wrong please try again later'}</p>
    </div>
  );
};
