import _ from 'lodash';
import { CODE_DESC_MAP } from './HttpCodeDetails';

const PLUGIN_METRICS = 'plugins.metrics-by-endpoint.';
const SUMMARY_START_LABEL = `${PLUGIN_METRICS}response_time.`;
const HTTP_CODES = 'http.codes.';
const HTTP_REQUESTS = 'http.requests';
const RATE_KEY = 'http.request_rate';
const METRIC_TITLE = 'Metrics Title';

export const getReportEnpointList = (report: any) => {
  const summaryKeyList = _.keys(report.summaries);
  const urlList = _.filter(summaryKeyList, (key: string) => {
    return _.startsWith(key, SUMMARY_START_LABEL);
  });
  const endpointList = _.map(urlList, (endpoint) => {
    return endpoint.replace(SUMMARY_START_LABEL, '');
  });
  return endpointList ?? [];
};

export const getEndpointList = (reportList: any[]) => {
  const enpointList: string[] = _.flatMap(reportList, (report) =>
    getReportEnpointList(report.aggregate)
  );
  const uniqEndpointList = _.uniq(enpointList);
  return uniqEndpointList;
};

export const getReportCodeObject = (report: any, keyStart: any) => {
  const filteredKeyList: string[] = _.keys(report.counters).filter((key) =>
    _.startsWith(key, keyStart)
  );
  const codeObject: any = {};
  let totalRequests = 0;
  for (let objKey of filteredKeyList) {
    const code = objKey.replace(keyStart, '');
    const codeKey = CODE_DESC_MAP[code] ? CODE_DESC_MAP[code] : `${HTTP_CODES}${code}`;
    codeObject[codeKey] = report.counters[objKey];
    totalRequests += report.counters[objKey];
  }
  codeObject[HTTP_REQUESTS] = totalRequests;
  codeObject[RATE_KEY] = report.rates[RATE_KEY];
  return codeObject;
};

export const getReportSummaryObject = (report: any, endpoint: any) => {
  const key = `${SUMMARY_START_LABEL}${endpoint}`;
  return report.summaries[key];
};
export const getTimeDuration = (start: number, end: number) => {
  const val = Math.round((new Date(end).getTime() - new Date(start).getTime()) / 1000) + ' sec';
  return val;
};
export const getFormattedReportTableData = (reportList: any[], endpoint: string) => {
  const formatedList = _.map(reportList, (report) => {
    return {
      ...getReportCodeObject(report.aggregate, `${PLUGIN_METRICS}${endpoint}.codes.`),
      ...getReportSummaryObject(report.aggregate, endpoint),
      [METRIC_TITLE]: getTimeDuration(
        report.aggregate.firstCounterAt,
        report.aggregate.lastCounterAt
      ),
    };
  });
  return generateTableData(formatedList);
};

const getErrorCount = (codeObject: any, startStr: string) => {
  const errorKeyList = _.keys(codeObject).filter(
    (key) =>
      key.startsWith(`4`) ||
      key.startsWith(`5`) ||
      key.startsWith(`${startStr}4`) ||
      key.startsWith(`${startStr}5`) ||
      key.startsWith('errors.')
  );
  let sum: any = 0;
  for (let key of errorKeyList) {
    sum += codeObject[key];
  }
  return sum;
};
export const getHttpSuccessData = (reportList: any[], endpoint: string) => {
  const formatedList = _.map(reportList, (report) => {
    const codeObject = getReportCodeObject(report.aggregate, `${PLUGIN_METRICS}${endpoint}.codes.`);
    const failureCount = getErrorCount(codeObject, HTTP_CODES);
    const name = report.aggregate.rates[RATE_KEY] + '/s';
    const duration = getTimeDuration(
      report.aggregate.firstCounterAt,
      report.aggregate.lastCounterAt
    );
    return {
      name: `${name}_${duration}`,
      success: codeObject[HTTP_REQUESTS] - failureCount,
      failure: failureCount,
    };
  });
  return formatedList;
};

export const getResponseTimeData = (reportList: any[], endpoint: string) => {
  const formatedList = _.map(reportList, (report) => {
    const summaryObject = getReportSummaryObject(report.aggregate, endpoint);
    const name = report.aggregate.rates[RATE_KEY] + '/s';
    const duration = getTimeDuration(
      report.aggregate.firstCounterAt,
      report.aggregate.lastCounterAt
    );
    return {
      name: `${name}_${duration}`,
      ...summaryObject,
    };
  });
  return formatedList;
};

export const getErrorObject = (report: any) => {
  const keyList = _.keys(report.counters);
  const errorKeys = _.filter(keyList, (key) => _.startsWith(key, 'errors.'));
  const errorObject: any = {};
  for (let key of errorKeys) {
    errorObject[key] = report.counters[key];
  }
  return errorObject;
};

export const getSummaryReportTableData = (reportList: any[]) => {
  const formatedList = _.map(reportList, (report) => {
    const reportCodeObject = getReportCodeObject(report.aggregate, `http.codes.`);
    reportCodeObject[HTTP_REQUESTS] = report.aggregate.counters['http.requests'];
    return {
      ...reportCodeObject,
      ...getErrorObject(report.aggregate),
      ...report.aggregate.summaries['http.response_time'],
      [METRIC_TITLE]: getTimeDuration(
        report.aggregate.firstCounterAt,
        report.aggregate.lastCounterAt
      ),
    };
  });
  return generateTableData(formatedList);
};

export const getSummaryHttpSuccessData = (reportList: any[]) => {
  const formatedList = _.map(reportList, (report) => {
    const codeObject = getReportCodeObject(report.aggregate, `http.codes.`);
    const errorObj = getErrorObject(report.aggregate);
    const failureCodeCount = getErrorCount({ ...codeObject, ...errorObj }, HTTP_CODES);
    // const failureErrorCount = _.sum(_.values(errorObj));
    // const totalFailureCount = failureCodeCount + failureErrorCount;
    const totalFailureCount = failureCodeCount;
    const name = report.aggregate.rates[RATE_KEY] + '/s';
    const duration = getTimeDuration(
      report.aggregate.firstCounterAt,
      report.aggregate.lastCounterAt
    );
    return {
      name: `${name}_${duration}`,
      success: report.aggregate.counters['http.requests'] - totalFailureCount,
      failure: totalFailureCount,
    };
  });
  return formatedList;
};

export const getSummaryResponseTimeData = (reportList: any[]) => {
  const formatedList = _.map(reportList, (report) => {
    const name = report.aggregate.rates[RATE_KEY] + '/s';
    const duration = getTimeDuration(
      report.aggregate.firstCounterAt,
      report.aggregate.lastCounterAt
    );
    return {
      name: `${name}_${duration}`,
      ...report.aggregate.summaries['http.response_time'],
    };
  });
  return formatedList;
};
const getOrderedList = (list: string[]) => {
  const codeOrder = [
    '1',
    'http.codes.1',
    '2',
    'http.codes.2',
    '3',
    'http.codes.3',
    '4',
    'http.codes.4',
    '5',
    'http.codes.5',
    'errors.',
  ];
  const codeList = _.flatMap(codeOrder, (start) => {
    return list.filter((item) => _.startsWith(item, start));
  });
  const finalList = [
    METRIC_TITLE,
    'http.request_rate',
    HTTP_REQUESTS,
    ...codeList,
    'min',
    'max',
    'median',
    'p90',
    'p95',
    'p99',
  ];
  return finalList;
};

const generateTableData = (formattedList: any[]) => {
  const keyList = _.flatMap(formattedList, (report) => _.keys(report));
  const uniqList = getOrderedList(_.uniq(keyList));
  const rows = _.map(uniqList, (rowHead: string) => {
    const rowObj = _.reduce(
      formattedList,
      (acc, report, index) => {
        return {
          ...acc,
          ['report_' + index]: report[rowHead],
        };
      },
      {}
    );
    return {
      key: rowHead,
      ...rowObj,
    };
  });
  return rows;
};
