import { useEffect, useState } from 'react';
import styles from './style.module.css';
import { parseQueryParams } from '../../modules/Utils/utils';
import { REPORTS } from '../../modules/constants';
import ErrorReport from '../common/ErrorReport';
import { getPdfReport } from '../../modules/Utils/HistoryReportUtils';
import Loader from '../common/Loader';
import BasicReport from '../BasicReport';
import APISummary from '../AdvancedReports/APISummary';
import { Recommendations } from '../AdvancedReports/Recommendations/Recommendations';
import { useAppSelector } from '../../redux/app/hooks';

const PdfReport = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>({});
  const reportStatus = useAppSelector((state) => ({
    [REPORTS.BASIC]: state.reportStatus[REPORTS.BASIC],
    [REPORTS.BACKEND_API]: state.reportStatus[REPORTS.BACKEND_API],
  }));

  useEffect(() => {
    const params = parseQueryParams(window.location.search.substring(1));
    if (!params[REPORTS.BASIC] || !params[REPORTS.BACKEND_API]) {
      setIsError(true);
      setIsLoading(false);
    } else {
      loadReportData(params);
    }
  }, []);

  const loadReportData = async (reportIdObject: any) => {
    try {
      setIsLoading(true);
      const reportData = await getPdfReport(reportIdObject);
      setData(reportData);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getGeneratedDate = () => {
    if (reportStatus && reportStatus[REPORTS.BASIC]) {
      return new Date(reportStatus[REPORTS.BASIC].createdAt).toLocaleString();
    }
    return null;
  };
  if (isLoading) {
    return (
      <div className={styles.pdf_loader_error__container}>
        <Loader loaderText="Loading report" />
      </div>
    );
  } else if (isError) {
    return (
      <div className={styles.pdf_loader_error__container}>
        <ErrorReport />
      </div>
    );
  }

  const getReporturl = () => {
    return reportStatus[REPORTS.BASIC]
      ? window.location.origin + '/site-history/' + reportStatus[REPORTS.BASIC].taskGroupId
      : '';
  };

  return (
    <div className={styles.pdf__container}>
      {/* <div className={styles.roadrunnr__logo}>
        <img src={Logo} alt="Roadrunnr" />
      </div> */}
      {/* <h1 className={styles.pdf_main__title}>Website Performance Report</h1>
      <div className={styles.pdf_sub__title}>
        <span className={styles.subtitle_label}>Website Analysed: </span>
        <span>{data[REPORTS.BASIC] ? data[REPORTS.BASIC].report?.url : ''}</span>
      </div> */}
      <div className={styles.date__header_container}>
        <h2 className={styles.date_footer_label}>Comprehensive report available at: </h2>
        <a href={getReporturl()} target="_blank" className={styles.site_link}>
          {getReporturl()}
        </a>
      </div>
      <div className={styles.date__header_container}>
        <span className={styles.date_header_label}>Report generated at: </span>
        <span>{getGeneratedDate()}</span>
      </div>
      <section>
        <div className={styles.basic_summary}>
          <h1>UI Summary</h1>
          <BasicReport
            basicReportData={data[REPORTS.BASIC]}
            isPdf={true}
            reportStatus={reportStatus[REPORTS.BASIC]}
          />
        </div>
        <br />
        <div className={styles.api_summary}>
          <h1>API Summary</h1>
          <APISummary
            data={data[REPORTS.BACKEND_API]}
            reportStatus={reportStatus[REPORTS.BACKEND_API]}
          />
        </div>
        <br />
        <div className={styles.pdf__recommendation_container}>
          <div className={styles.date__header_container}>
            <h2 className={styles.date_footer_label}>Comprehensive report available at: </h2>
            <a href={getReporturl()} target="_blank" className={styles.site_link}>
              {getReporturl()}
            </a>
          </div>
          <h1>Recommendations</h1>
          <Recommendations />
        </div>
        <br />
        {/* Hidden values to be accessed by pdf generator */}
        <div className={styles.pdf__hidden_values} id="generated_date">
          {getGeneratedDate()}
        </div>
        <div className={styles.pdf__hidden_values} id="website_url">
          {data[REPORTS.BASIC] ? data[REPORTS.BASIC].report?.url : ''}
        </div>
      </section>
    </div>
  );
};

export default PdfReport;
