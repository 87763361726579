import { AccessTimeOutlined, LinkOutlined } from '@mui/icons-material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import styles from './styles.module.css';
import { Button } from '@mui/material';
import { getPdfReport } from '../../modules/Utils/reportsUtil';
import { REPORTS } from '../../modules/constants';

type TProps = {
  advancedData: any;
  showDownload: boolean;
};
const HeaderSection = (props: TProps) => {
  const { advancedData, showDownload = false } = props;

  const downloadHandler = () => {
    if (advancedData.taskId) {
      getPdfReport(advancedData.taskId[REPORTS.BASIC], advancedData.taskId[REPORTS.BACKEND_API]);
    }
  };

  return (
    <div className={styles.title__container}>
      <div className={styles.header_section_left}>
        <div className={styles.url__container}>
          <LinkOutlined sx={{ color: 'var(--primary-color-light-variant)', fontSize: '16px' }} />
          <div>
            {' '}
            {advancedData.url ? advancedData.url.replace('https://', '').split('/')[0] : ''}{' '}
          </div>
        </div>
        {advancedData.createdAt ? (
          <div className={styles.time__container}>
            <AccessTimeOutlined
              sx={{ color: 'var(--primary-color-light-variant)', fontSize: '14px' }}
            />

            <div> {`Generated at ${new Date(advancedData.createdAt).toLocaleString()}`} </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {advancedData && advancedData.userEmail && showDownload && (
        <div className={styles.download__button__container}>
          <Button
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={downloadHandler}
            className={styles.download__button}
          >
            Download
          </Button>
        </div>
      )}
    </div>
  );
};

export default HeaderSection;
