import { useState } from 'react';
import UrlSelector from './UrlSelector';
import ReportConatiner from './ReportConatiner';
import styles from './summary.module.css';
import { Paper } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

type TProps = {
  advancedReport: any;
};
const DetailsComponent = (props: TProps) => {
  const { advancedReport } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  if (!advancedReport) {
    return null;
  }
  return (
    <div>
      <div className={styles.section_description}>
        <DescriptionIcon sx={{ fontSize: '20px' }} />
        <div>These are detailed report for each url</div>
      </div>
      <br />
      <UrlSelector
        selectedIndex={selectedIndex}
        onChangeHandler={setSelectedIndex}
        urlList={advancedReport}
      />
      <br />
      <br />
      <ReportConatiner
        url={advancedReport[selectedIndex].url}
        reportData={advancedReport[selectedIndex]}
      />
    </div>
  );
};

export default DetailsComponent;
