import _ from 'lodash';

export const formatNumber = (number: number, toFixed: number = 2): string => {
  return number % 1 !== 0 ? number.toFixed(toFixed) : number.toString();
};

type query = { [key: string]: string };

export const parseQueryParams = (query: string): query => {
  const queryParams = query.split('&');
  const parsedQueryParams = {} as query;
  queryParams.forEach((param) => {
    const [paramName, paramValue] = param.split('=');
    parsedQueryParams[paramName] = paramValue;
  });
  return parsedQueryParams;
};

export const stringifyQueryParams = (data: query): string => {
  let str = '?';
  Object.keys(data).forEach((key) => {
    str += `${key}=${data[key]}&`;
  });
  return str.slice(0, -1);
};

export const getProgressBarColorObject = (score: number) => {
  let baseColor = '#c1e0c055';
  let barColor = '#5bbb58';
  if (score < 0.49) {
    baseColor = '#ff696155';
    barColor = '#df3f36';
  } else if (score < 0.89) {
    baseColor = '#f9e07655';
    barColor = '#e3c513';
  }
  return {
    baseColor,
    barColor,
  };
};

export const getErrorMessage = (executionNote: any) => {
  if (executionNote && (_.startsWith(executionNote, '{') || _.startsWith(executionNote, '['))) {
    const parsedNote = JSON.parse(executionNote);
    if (_.isArray(parsedNote) || _.startsWith(executionNote, '[')) {
      //TODO: Get format and make changes
      return executionNote;
    }
    return parsedNote.error ?? 'Something went wrong';
  }
  return executionNote ?? 'Something went wrong';
};
