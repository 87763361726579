import { Button } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import styles from './style.module.css';

type TProps = {
  errorMessage: string;
  onClose: any;
};
const ErrorComponent = (props: TProps) => {
  const { errorMessage, onClose } = props;
  return (
    <div className={styles.error__component}>
      <div className={styles.error_message__container}>
        <ErrorOutlineOutlinedIcon className={styles.error__icon} />
        <p> {errorMessage ?? 'Something went wrong please try again later'}</p>
        <p>Please try again later</p>
      </div>
      <div className={styles.button__controllers}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default ErrorComponent;
