import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './style.module.css';
import FullDonut from '../common/FullDonut';
import { getProgressBarColorObject } from '../../modules/Utils/utils';
import { getStackIcon } from './HistoryTableUtil';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

type TProps = {
  data: any[];
};
const MobileCardListContainer = (props: TProps) => {
  const { data } = props;
  return (
    <div className={styles.mobile__container}>
      <div className={styles.mobile__header}>The latest webshop performance reports</div>
      {data.map((item) => {
        const performanceColor = getProgressBarColorObject(item.performanceScore);
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className={`${styles.history__card}`}>
                <div className={styles.history__card_chart}>
                  <FullDonut
                    value={item.performanceScore * 100}
                    baseColor={performanceColor.baseColor}
                    barColor={performanceColor.barColor}
                  />
                </div>
                <div className={styles.history__card_text_container}>
                  <div className={styles.history__card_url}>{item.url}</div>
                  <div className={styles.history__card_date}>
                    {new Date(item.createdAt).toLocaleString()}
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.mobile__details_container}>
                <div className={styles.mobile__title}>Metrics</div>
                <div className={styles.mobile__metrics_container}>
                  {[
                    { id: 'TBT', score: item.tbt },
                    { id: 'LCP', score: item.lcp },
                    { id: 'CLS', score: item.cls },
                  ].map((audit: any) => {
                    const metricColor = getProgressBarColorObject(audit.score);
                    return (
                      <div>
                        <div className={styles.chart__container}>
                          <FullDonut
                            value={audit.score * 100}
                            baseColor={metricColor.baseColor}
                            barColor={metricColor.barColor}
                          />
                        </div>
                        <div className={styles.mobile__metric_label}>{audit.id}</div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.mobile__metrics_container}>
                  {[
                    { id: 'FCP', score: item.fcp },
                    { id: 'SI', score: item.si },
                  ].map((audit: any) => {
                    const metricColor = getProgressBarColorObject(audit.score);
                    return (
                      <div>
                        <div className={styles.chart__container}>
                          <FullDonut
                            value={audit.score * 100}
                            baseColor={metricColor.baseColor}
                            barColor={metricColor.barColor}
                          />
                        </div>
                        <div className={styles.mobile__metric_label}>{audit.id}</div>
                      </div>
                    );
                  })}
                </div>
                <br />
                <div className={styles.mobile__title}>Software used</div>
                <div className={styles.mobile__stack_conatainer}>
                  <img src={getStackIcon(item.stack)} height={20} alt={item.stack} />
                </div>
                <br />
                <div className={styles.mobile__details__link_container}>
                  <a
                    href={
                      'https://app.roadrunnerhq.com/site-history/' + item.taskStatus.taskGroupId
                    }
                    target="blank"
                  >
                    <ArrowCircleRightOutlinedIcon style={{ fontSize: '30px' }} />
                  </a>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default MobileCardListContainer;
