import { useMemo, memo, useEffect } from 'react';

import { Grid } from '@mui/material';

import AnalyzeReportHome from './AnalyzeReportHome';

import { getReportFromQueryParams } from '../../modules/Utils/reportsUtil';
import { parseQueryParams } from '../../modules/Utils/utils';
import style from './section.module.css';
import HistoryConatiner from './UserHistory';

const Section = memo(() => {
  const breackpointValues = useMemo(() => {
    return {
      xs: 0, // 0px
      sm: 0, // >= 600px
      md: 1, // >= 900px
      lg: 2, // >= 1200px
      xl: 2, // >= 1536px
      total: 12,
      url: parseQueryParams(window.location.search.substring(1))?.url || '',
    };
  }, []);

  useEffect(() => {
    getReportFromQueryParams();
  }, []);

  const { xs, sm, md, lg, xl, total, url } = breackpointValues;

  return (
    <div className={style.section__container}>
      <div className={style.left_dummy_div}></div>
      <AnalyzeReportHome url={url} />
      <HistoryConatiner />
    </div>
  );
});

export default Section;
