import HistoryList from '../../common/HistoryList';
import styles from './style.module.css';
import { useAppSelector } from '../../../redux/app/hooks';
import { useEffect, useState } from 'react';
import { getUserHistoryList, loadHistory } from '../../../modules/Utils/HistoryReportUtils';
import _ from 'lodash';
import { REPORTS } from '../../../modules/constants';

const HistoryConatiner = () => {
  const [userHistory, setUserHistory] = useState<any[]>([]);
  const userData = useAppSelector((state) => ({
    userEmail: state.auth.userEmail,
    uid: state.auth.uid,
    advancedReport: state.reports[REPORTS.ADVANCED],
    taskGroupId: state.reports.taskGroupId,
  }));

  useEffect(() => {
    if (userData.uid && userData.userEmail) {
      fetchUserHistory();
    }
  }, [userData.taskGroupId, userData.uid]);

  useEffect(() => {
    if (!userData.uid) {
      setUserHistory([]);
    }
  }, [userData.uid]);

  const fetchUserHistory = async () => {
    try {
      const response = await getUserHistoryList(userData.uid);
      const data = response?.data?.user?.taskStatus;
      if (data) {
        const processsedData = _.map(data, (item: any) => {
          return { ...item, id: item.taskGroupId, createdAt: item.taskList[0]?.createdAt };
        });
        const reversed = processsedData.reverse();
        setUserHistory(reversed);
      }
    } catch (e) {
      //TODO; Handle error
    }
  };

  const onSelect = (data: any) => {
    loadHistory(data);
  };
  return (
    <div className={`${styles.history__section} ${userHistory.length ? styles.not_empty : ''}`}>
      {userHistory.length ? (
        <div className={styles.history__container}>
          <h1>History</h1>
          <p className={styles.res__count}>Lastest Results</p>
          <HistoryList
            data={userHistory}
            onSelect={onSelect}
            selected={{ id: userData.taskGroupId }}
            enableErrorIndicator
          />
        </div>
      ) : null}
    </div>
  );
};

export default HistoryConatiner;
