import { getErrorMessage } from '../../../modules/Utils/utils';
import { AUDIT_ERROR_STATUS } from '../../../modules/constants';
import Card from './Card';
import _ from 'lodash';

type TProps = {
  data: any[];
  onSelect: Function;
  selected?: any;
  enableErrorIndicator?: boolean;
};
const HistoryList = (props: TProps) => {
  const { data, onSelect, selected, enableErrorIndicator = false } = props;

  const getErrorStatus = (item: any) => {
    if (item.taskList) {
      const errorTask = _.find(item.taskList, { status: AUDIT_ERROR_STATUS });
      if (errorTask) {
        return `Report generated with errors: ${getErrorMessage(errorTask.executionNote) ?? ''}`;
      }
    }
    return null;
  };

  return (
    <div>
      {data.map((item) => {
        return (
          <Card
            data={item}
            onSelect={onSelect}
            isSelected={selected ? selected.id === item.id : false}
            errorMessage={enableErrorIndicator ? getErrorStatus(item) : null}
          />
        );
      })}
    </div>
  );
};

export default HistoryList;
