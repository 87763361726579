import { Checkbox } from '@mui/material';
import styles from './style.module.css';

type TProps = {
  labelList: string[];
  selectedIndexList: number[];
  onSelectionChange: any;
  labelKey?: string;
};
const ListSelector = (props: TProps) => {
  const { labelList, selectedIndexList, onSelectionChange, labelKey } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedList = [...selectedIndexList];
    if (event.target.checked) {
      updatedList.push(index);
    } else {
      const pos = selectedIndexList.indexOf(index);
      pos !== -1 && updatedList.splice(pos, 1);
    }
    onSelectionChange(updatedList);
  };

  const getTrimmedValue = (value: string) => {
    return value.length > 200 ? value.slice(0, 200) + '...' : value;
  };
  return (
    <div>
      {labelList.map((item: any, index: number) => {
        return (
          <div className={styles.list_item}>
            <Checkbox
              checked={selectedIndexList.includes(index)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                color: '#543e86',
                '&.Mui-checked': {
                  color: '#543e86',
                },
              }}
            />
            <div>{getTrimmedValue(labelKey ? item[labelKey] : item)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ListSelector;
