import styles from './style.module.css';
import { getCumulativeWeightComponent } from '../OpportunityAndDiagnostic/ODUtils';
import RectangleProgressBar from '../RectangleProgressBar';
import { getProgressBarColorObject } from '../../../modules/Utils/utils';

type TProps = {
  thumbnails: any[];
  mobile?: boolean;
  speedIndex?: any;
};
const ScreenshotContainer = (props: TProps) => {
  const { thumbnails, mobile = false, speedIndex } = props;

  const getSpeedIndexComponent = () => {
    if (!speedIndex) return null;

    const { baseColor, barColor } = getProgressBarColorObject(speedIndex.score);
    return (
      <div>
        <RectangleProgressBar
          value={speedIndex.score ? speedIndex.score * 100 : 0}
          baseColor={baseColor}
          barColor={barColor}
          height={8}
          borderRadius={0}
        />
      </div>
    );
  };
  return (
    <div className={styles.thumbnail__container}>
      <div className={'title__text'}> Speed visualization</div>
      {getSpeedIndexComponent()}
      <div className={styles.thumbnail__img_container}>
        {thumbnails &&
          thumbnails.map((thumbnail: any, index: number) => {
            return (
              <div key={index}>
                <img src={thumbnail?.data} alt={'page load preview'} width={mobile ? 92 : 94} />
                <div className={styles.img__label}>{thumbnail?.timing / 1000}s</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ScreenshotContainer;
