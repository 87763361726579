import { HelpOutlineOutlined } from '@mui/icons-material';

import styles from './recommendations.module.css';
import { RECOMMENDATIONS, RECOMMENDATIONS_TYPE, TYPES } from './_recommendations';

export const Recommendations = () => {
  return (
    <>
      {Object.keys(RECOMMENDATIONS).map((title, index) => {
        return (
          <div key={index}>
            <div className={styles.title__container}>
              <p className={styles.title}>{title}</p>
              <HelpOutlineOutlined className={styles.title__help} />
            </div>

            <div className={styles.data__container}>
              {(
                RECOMMENDATIONS[title as keyof typeof RECOMMENDATIONS] as RECOMMENDATIONS_TYPE[]
              ).map((categories: RECOMMENDATIONS_TYPE, subindex: number) => {
                if (categories.type === TYPES.CHILD_TITLE) {
                  return (
                    <div className={styles.title__container} key={`${index}_${subindex}`}>
                      <p className={styles.child__title}>{categories.text}</p>
                      <HelpOutlineOutlined className={styles.title__help} />
                    </div>
                  );
                }

                if (categories.type === TYPES.SUB_TITLE) {
                  return (
                    <p key={`${index}_${subindex}`} className={styles.sub__title}>
                      {categories.text}
                    </p>
                  );
                }

                if (categories.type === TYPES.LIST) {
                  const key = `${index}_${subindex}`;
                  return (
                    <ul className={styles.suggestion__container} key={key}>
                      {categories.children!.map((li: string, childIndex: number) => {
                        return (
                          <li key={`${key}_${childIndex}`} className={styles.suggestion}>
                            {li}
                          </li>
                        );
                      })}
                    </ul>
                  );
                }

                return null;
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
