import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styles from './style.module.css';

type TProps = {
  data: any[];
  color: string;
};

const HttpCodeTable = (props: TProps) => {
  const { data, color } = props;
  return (
    <TableContainer className={styles.http_code_table_container}>
      <Table sx={{ minWidth: 650 }} aria-label="http table">
        <TableHead>
          <TableRow>
            <TableCell className={styles.http_code_table_col}>Code</TableCell>
            <TableCell className={styles.http_code_table_col}>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow>
              <TableCell className={styles.http_code_table_col_label}>
                <div className={styles[`http_table_label_tag_${color}`]}>{row.label}</div>
              </TableCell>
              <TableCell className={styles.http_code_table_col}>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HttpCodeTable;
