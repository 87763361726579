import styles from './style.module.css';
import _ from 'lodash';

type TProps = {
  filterList: string[];
  selectedFilter: string;
  onChangeHandler: Function;
};
const Filters = (props: TProps) => {
  const { filterList, selectedFilter, onChangeHandler } = props;
  return (
    <div className={styles.filter_conatiner}>
      {filterList.map((filter) => {
        return (
          <div
            className={`${styles.filter_item} ${selectedFilter === filter ? styles.selected : ''}`}
            onClick={()=>onChangeHandler(filter)}
          >
            {_.startCase(filter)}
          </div>
        );
      })}
    </div>
  );
};

export default Filters;
