import { Box, Button } from '@mui/material';
import ListSelector from '../../common/ListSelector';
import styles from './style.module.css';
import ReplayIcon from '@mui/icons-material/Replay';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import ErrorMessage from './ErrorMessage';

type TProps = {
  routes: string[];
  selectedindexList: number[];
  onSelectionChange: Function;
  additionalError: string;
  additionalUrls: any[];
  selectedAdditionalUrlIndexes: number[];
  setSelectedAdditionalUrlsIndexes: Function;
};
const MAX_ERROR = 'Maximum possible count is 5. Please uncheck others and try selecting again';
const RouteSelector = (props: TProps) => {
  const {
    routes,
    selectedindexList,
    onSelectionChange,
    additionalError,
    additionalUrls,
    selectedAdditionalUrlIndexes,
    setSelectedAdditionalUrlsIndexes,
  } = props;

  const [error, setError] = useState('');

  const onSelect = (updatedIndexList: number[]) => {
    setError('');
    if (updatedIndexList.length + selectedAdditionalUrlIndexes.length > 5) {
      setError(MAX_ERROR);
    } else {
      onSelectionChange(updatedIndexList);
    }
  };

  const onAdditionalUrlSelect = (updatedIndexList: number[]) => {
    setError('');
    if (updatedIndexList.length + selectedindexList.length > 5) {
      setError(MAX_ERROR);
    } else {
      setSelectedAdditionalUrlsIndexes(updatedIndexList);
    }
  };
  return (
    <div>
      <div className={styles.wizard__title}>Choose API Routes to be analysed</div>
      <div className={styles.wizard__info}>Maximum routes that can be analysed is 5.</div>
      <h4 className={styles.wizard_route_info_title}>
        These are the routes fetched from home page
      </h4>
      {error && <ErrorMessage errorMessage={error} />}
      <div className={styles.wizard_route_list}>
        {routes.length ? (
          <ListSelector
            labelList={routes}
            selectedIndexList={selectedindexList}
            onSelectionChange={onSelect}
            labelKey="name"
          />
        ) : (
          <div className={styles.wizard_empty_list_msg}>No routes found</div>
        )}
        <div className={styles.others_section}>
          {additionalError ? (
            <ErrorMessage errorMessage={additionalError} />
          ) : additionalUrls.length ? (
            <div className={styles.additional_url_container}>
              <h4 className={styles.wizard_route_info_title}>
                These are the routes fetched from other page
              </h4>
              <ListSelector
                labelList={additionalUrls}
                selectedIndexList={selectedAdditionalUrlIndexes}
                onSelectionChange={onAdditionalUrlSelect}
                labelKey="name"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RouteSelector;
