import { configureStore } from '@reduxjs/toolkit';

import authReducer from '../features/Auth/authSlice';
import snackReducer from '../features/SnackBar/snackBarSlice';
import reportsReducer from '../features/Reports/reportsSlice';
import reportStatusReducer from '../features/Reports/reportStatusSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    snackBar: snackReducer,
    reports: reportsReducer,
    reportStatus: reportStatusReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
