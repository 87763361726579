import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './style.module.css';
import CustomTick from './CustomTick';

type TProps = {
  reportData: any[];
};
const HttpSuccessChart = (props: TProps) => {
  const { reportData } = props;
  return (
    <section className={styles.graph__container}>
      <div className={styles.http__success_conatiner}>
        <p className="title__text_bold">Http Status Report</p>
        <br />
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={reportData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={45}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={'name'} tick={<CustomTick />} />
            <YAxis />
            <Tooltip />
            <Legend wrapperStyle={{ bottom: '-25px' }} />
            <Bar dataKey="success" stackId="a" fill="#055c38" animationDuration={500} />
            <Bar dataKey="failure" stackId="a" fill="#ec5e5e" animationDuration={500} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.graph__description}>
        Report highlights http requests send and responses received at various loads starting from 1
        per second to 20 per second. Successful responses are highlighted in green and failures in
        red. A failure indicates a less than ideal experience to a user browsing the site.
      </div>
    </section>
  );
};
export default HttpSuccessChart;
