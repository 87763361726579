import { useCallback, useRef, useState } from 'react';

import { Avatar, Button, ClickAwayListener, Divider, Grow, Paper, Popper } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { useAppDispatch } from '../../redux/app/hooks';
import { logout } from '../../redux/features/Auth/authSlice';
import { UserDetailsProps } from './types';

import styles from './userdetails.module.css';

export const UserDetails = ({ userDetails }: UserDetailsProps) => {
  const [open, setOpen] = useState(false);
  const avatarRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: MouseEvent | TouchEvent) => {
    if (avatarRef.current && avatarRef.current.contains(event.target as Node | null)) {
      return;
    }
    setOpen(false);
  }, []);

  return (
    <>
      <Avatar
        ref={avatarRef}
        variant="circular"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            handleToggle();
          }
        }}
        className={styles.avatar}
        sx={{
          ...(open && { outline: 'var(--primary-outline-color) solid 2px' }),
        }}
      >
        {userDetails.userName[0].toUpperCase()}
      </Avatar>

      <Popper
        open={open}
        anchorEl={avatarRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 99 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Paper elevation={4} style={{ padding: 15, marginTop: '1em' }}>
                  <div>
                    <strong> {userDetails.userName} </strong>
                  </div>
                  <div className={styles.email}>{userDetails.userEmail}</div>
                  <Divider />
                  <Button
                    variant="text"
                    className={styles.logout__button}
                    onClick={() => dispatch(logout())}
                  >
                    <LogoutIcon className={styles.logout__icon} />
                    Logout
                  </Button>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
