import { TOP_FIVE_ENUM } from "../../../modules/Utils/constants";

export type SummaryReport = {
  key: string;
  cumulative: number;
  max: number;
  min: number;
  mean: number;
  numericValueDistribution?: [number];
  numericUnit?: string;
  distribution: [any];
  distributionLabels: [any];
  listedUrls: [string];
  title?: string;
};
export type Summary = {
  [key: string]: SummaryReport;
};

export const getPerformanceSummary = (data: any, device: string) => {
  let deviceReport = data?.advancedReport['report']?.map((item: any) => {
    return item[device];
  });
  let speedIndexDistribution = [];
  let cumulativeLayoutShiftDistribution = [];
  let totalBlockingTimeDistribution = [];
  let largestContentfulPaintDistribution = [];
  let firstContentfulPaintDistribution = [];
  let firstMeaningfulPaintDistribution = [];
  let interactiveDistribution = [];
  for (let report of deviceReport) {
    speedIndexDistribution.push(report['lighthouseResult']['audits']['speed-index']['score']);
    cumulativeLayoutShiftDistribution.push(
      report['lighthouseResult']['audits']['cumulative-layout-shift']['score']
    );
    totalBlockingTimeDistribution.push(
      report['lighthouseResult']['audits']['total-blocking-time']['score']
    );
    largestContentfulPaintDistribution.push(
      report['lighthouseResult']['audits']['largest-contentful-paint']['score']
    );
    firstContentfulPaintDistribution.push(
      report['lighthouseResult']['audits']['first-contentful-paint']['score']
    );
    firstMeaningfulPaintDistribution.push(
      report['lighthouseResult']['audits']['first-meaningful-paint']['score']
    );
    interactiveDistribution.push(report['lighthouseResult']['audits']['interactive']['score']);
  }
  let result = {
    [TOP_FIVE_ENUM.SPEED_INDEX]: {
      mean: speedIndexDistribution.reduce((a, b) => a + b, 0) / speedIndexDistribution.length,
      min: Math.min(...speedIndexDistribution),
      max: Math.max(...speedIndexDistribution),
      cumulative: speedIndexDistribution.reduce((a, b) => a + b, 0),
      id: TOP_FIVE_ENUM.SPEED_INDEX,
      score: speedIndexDistribution.reduce((a, b) => a + b, 0) / speedIndexDistribution.length,
    },
    [TOP_FIVE_ENUM.CUMULATIVE_LAYOUT_SHIFT]: {
      mean:
        cumulativeLayoutShiftDistribution.reduce((a, b) => a + b, 0) /
        cumulativeLayoutShiftDistribution.length,
      min: Math.min(...cumulativeLayoutShiftDistribution),
      max: Math.max(...cumulativeLayoutShiftDistribution),
      cumulative: cumulativeLayoutShiftDistribution.reduce((a, b) => a + b, 0),
      id: TOP_FIVE_ENUM.CUMULATIVE_LAYOUT_SHIFT,
      score:
        cumulativeLayoutShiftDistribution.reduce((a, b) => a + b, 0) /
        cumulativeLayoutShiftDistribution.length,
    },
    [TOP_FIVE_ENUM.TOTAL_BLOCKING_TIME]: {
      mean:
        totalBlockingTimeDistribution.reduce((a, b) => a + b, 0) /
        totalBlockingTimeDistribution.length,
      min: Math.min(...totalBlockingTimeDistribution),
      max: Math.max(...totalBlockingTimeDistribution),
      cumulative: totalBlockingTimeDistribution.reduce((a, b) => a + b, 0),
      id: TOP_FIVE_ENUM.TOTAL_BLOCKING_TIME,
      score:
        totalBlockingTimeDistribution.reduce((a, b) => a + b, 0) /
        totalBlockingTimeDistribution.length,
    },
    [TOP_FIVE_ENUM.LARGEST_CONTENTFUL_PAINT]: {
      mean:
        largestContentfulPaintDistribution.reduce((a, b) => a + b, 0) /
        largestContentfulPaintDistribution.length,
      min: Math.min(...largestContentfulPaintDistribution),
      max: Math.max(...largestContentfulPaintDistribution),
      cumulative: largestContentfulPaintDistribution.reduce((a, b) => a + b, 0),
      id: TOP_FIVE_ENUM.LARGEST_CONTENTFUL_PAINT,
      score:
        largestContentfulPaintDistribution.reduce((a, b) => a + b, 0) /
        largestContentfulPaintDistribution.length,
    },
    [TOP_FIVE_ENUM.FIRST_CONTENTFUL_PAINT]: {
      mean:
        firstContentfulPaintDistribution.reduce((a, b) => a + b, 0) /
        firstContentfulPaintDistribution.length,
      min: Math.min(...firstContentfulPaintDistribution),
      max: Math.max(...firstContentfulPaintDistribution),
      cumulative: firstContentfulPaintDistribution.reduce((a, b) => a + b, 0),
      id: TOP_FIVE_ENUM.FIRST_CONTENTFUL_PAINT,
      score:
        firstContentfulPaintDistribution.reduce((a, b) => a + b, 0) /
        firstContentfulPaintDistribution.length,
    },
  };
  return result;
};

export const getPerformanceScoreObject = (data: any, device: string) => {
  let deviceReport = data?.advancedReport['report']?.map((item: any) => {
    return item[device];
  });
  let performanceScoreDistribution = [];
  for (let report of deviceReport) {
    performanceScoreDistribution.push(
      report['lighthouseResult']['categories']['performance']['score']
    );
  }
  return {
    mean:
      performanceScoreDistribution.reduce((a, b) => a + b, 0) / performanceScoreDistribution.length,
    min: Math.min(...performanceScoreDistribution),
    max: Math.max(...performanceScoreDistribution),
    cumulative: performanceScoreDistribution.reduce((a, b) => a + b, 0),
  };
};

export const getSummary = (data: any, device: string) => {
  let deviceReport = data?.advancedReport['report']?.map((item: any) => {
    return item[device];
  });

  let visited: Summary = {};
  for (const item of deviceReport) {
    let keys = Object.keys(item);
    let lhr = item['lighthouseResult']['audits'];
    for (const key of keys) {
      let weight = item[key]['weight'];
      let score = item[key]['score'];
      let lhrItem = lhr[key];
      if (lhrItem == undefined) continue;
      let numericValue = lhrItem['numericValue'];
      let numericUnit = lhrItem['numericUnit'];
      if (weight == undefined || score == undefined) continue;
      if (!visited[key]) {
        visited[key] = {
          key: key,
          cumulative: 0,
          max: 0,
          min: 0,
          mean: 0,
          distribution: [item[key]['score']],
          distributionLabels: [item[key]['displayValue']],
          listedUrls: [item['url']],
          title: lhrItem['title'],
        };
        if (numericValue != undefined) visited[key].numericValueDistribution = [numericValue];
        if (numericUnit != undefined) visited[key].numericUnit = numericUnit;
      } else {
        visited[key].listedUrls.push(item['url']);
        visited[key].distribution.push(item[key]['score']);
        visited[key].distributionLabels.push(item[key]['displayValue']);
        if (numericValue != undefined) visited[key].numericValueDistribution?.push(numericValue);
      }
    }
  }

  for (const key in visited) {
    let item = visited[key];
    item.max = Math.max(...item.distribution);
    item.min = Math.min(...item.distribution);
    item.mean = item.distribution.reduce((a, b) => a + b, 0) / item.distribution.length;
    item.cumulative = item.distribution.reduce((a, b) => a + b, 0);
  }
  return visited;
};
