import { MetricProps } from './types';

import styles from './metic.module.css';

const getTextColor = (value: number): React.CSSProperties => {
  if (value >= 90) {
    return { color: 'var(--pass-text-color)' };
  }
  if (value >= 50) {
    return { color: 'var(--fail-text-color)' };
  }
  return { color: 'var(--average-text-color)' };
};

const getIconClass = (value: number): string => {
  if (value >= 90) {
    return styles.success__icon;
  }
  if (value >= 50) {
    return styles.average__icon;
  }
  return styles.error__icon;
};

export const Metric = ({ title, value, displayValue }: MetricProps) => {
  return (
    <div className={styles.metric__container}>
      <div className={styles.inner__container}>
        <div className={`${styles.icon} ${getIconClass(value)}`} />
        <div className={styles.title}>{title}</div>
        <div style={getTextColor(value)} className={styles.value}>
          {displayValue}
        </div>
      </div>
    </div>
  );
};
