import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import _ from 'lodash';
import RectangleProgressBar from '../RectangleProgressBar';
import styles from './style.module.css';
import { getProgressBarColorObject } from '../../../modules/Utils/utils';

export enum R_TYPES {
  DIAGNOSTIC = 'diagnostic',
  OPPORTUNITY = 'opportunity',
}

export enum FIELDS {
  TITLE = 'title',
  DISPLAY_VALUE = 'displayValue',
  CULMULATIVE_WEIGHT = 'cumulativeWeight',
  METRIC_KEYS = 'metricKeys',
  SCORE = 'score',
  WEIGHT = 'weight',
  DESCRIPTION = 'description',
  DETAILS = 'details',
  CUMULATIVE_WEIGHT_VALUE = 'cumulativeWeightValue',
}

export const COLUMNS = [FIELDS.TITLE, FIELDS.CULMULATIVE_WEIGHT, FIELDS.DISPLAY_VALUE];

export const RESULT_TYPE = 'resultType';

export const getDataObjectList = (reportObject: any, rType: R_TYPES) => {
  const resultData = [];
  for (let key in reportObject) {
    const dataItem = reportObject[key];
    if (dataItem[RESULT_TYPE] === rType) {
      const item = {
        [FIELDS.TITLE]: dataItem[FIELDS.TITLE],
        [FIELDS.DISPLAY_VALUE]: dataItem[FIELDS.DISPLAY_VALUE],
        [FIELDS.CUMULATIVE_WEIGHT_VALUE]: dataItem[FIELDS.CULMULATIVE_WEIGHT] ?? 0,
        [FIELDS.CULMULATIVE_WEIGHT]: getCumulativeWeightComponent(
          dataItem[FIELDS.SCORE],
          dataItem[FIELDS.CULMULATIVE_WEIGHT]
        ),
        [FIELDS.METRIC_KEYS]: dataItem[FIELDS.METRIC_KEYS],
        [FIELDS.SCORE]: dataItem[FIELDS.SCORE],
        [FIELDS.WEIGHT]: dataItem[FIELDS.WEIGHT],
        [FIELDS.DESCRIPTION]: dataItem[FIELDS.DESCRIPTION],
        [FIELDS.DETAILS]: getDetailsComponent(
          dataItem[FIELDS.DESCRIPTION],
          dataItem[FIELDS.DETAILS]
        ),
      };
      resultData.push(item);
    }
  }
  const sortedData = _.sortBy(resultData, [FIELDS.SCORE, FIELDS.CUMULATIVE_WEIGHT_VALUE]);
  return sortedData;
};

const getDetailsComponent = (description: string, details: any) => {
  if (details.headings !== undefined && Array.isArray(details.headings)) {
    const headings = details.headings;
    const items = details.items;
    if (items?.length > 0) {
      return (
        <>
          <p>{description}</p>
          <h3>Resource List</h3>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headings.map((value: any, index: number) => {
                    return <TableCell key={index}>{value.label}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((value: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      {headings.map((heading: any, index: number) => {
                        let textToShow = value[heading.key];
                        if (typeof textToShow === 'object') {
                          textToShow = JSON.stringify(textToShow);
                        }
                        return <TableCell key={index}>{textToShow}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    } else {
      return <p>{description}</p>;
    }
  }
  return <p>{description}</p>;
};

export const getCumulativeWeightComponent = (score: number, cumulativeWeight: number) => {
  const { baseColor, barColor } = getProgressBarColorObject(score);
  return (
    <div className={styles.weight_container}>
      <div style={{ color: barColor }} className={styles.value_div}>
        {cumulativeWeight ?? 0}
      </div>
      <RectangleProgressBar
        value={cumulativeWeight ?? 0}
        baseColor={baseColor}
        barColor={barColor}
      />
    </div>
  );
};
