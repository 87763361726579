import { AUDIT_ERROR_STATUS } from '../../../modules/constants';
import styles from '../advancedreport.module.css';
import Loader from '../../common/Loader';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useCallback } from 'react';
import {
  getSummaryHttpSuccessData,
  getSummaryReportTableData,
  getSummaryResponseTimeData,
  getEndpointList,
} from '../APIUtils';
import APIReport from '../APIReport';
import UrlList from '../../common/URLList';
import _ from 'lodash';
import APIErrorComponent from '../APIErrorComponent';
import { getErrorMessage } from '../../../modules/Utils/utils';

type TProps = {
  data: any;
  reportStatus?: any;
};

const APISummary = (props: TProps) => {
  const { data, reportStatus } = props;

  const getReportData = () => {
    return data.report;
  };
  const getReportTableData = useCallback(() => {
    const reportList = getReportData();
    return getSummaryReportTableData(reportList);
  }, [data?.report]);

  const getHttpSuccessData = useCallback(() => {
    const reportList = getReportData();
    return getSummaryHttpSuccessData(reportList);
  }, [data?.report]);

  const getResponseTimeData = useCallback(() => {
    const reportList = getReportData();
    return getSummaryResponseTimeData(reportList);
  }, [data?.report]);

  const getUrlList = useCallback(() => {
    const reportList = getReportData();
    const endpoints = getEndpointList(reportList);
    return _.map(endpoints, (url: string) => {
      return { url };
    });
  }, [data?.report]);

  return (
    <>
      {data === AUDIT_ERROR_STATUS ? (
        <div className={`${styles.loader__container} ${styles.advanced__reports__container}`}>
          <APIErrorComponent reason={getErrorMessage(reportStatus?.executionNote)} />
        </div>
      ) : !data ? (
        <div className={`${styles.loader__container} ${styles.advanced__reports__container}`}>
          <Loader loaderText="API Report may take a while" />
        </div>
      ) : (
        <div>
          <UrlList urlList={getUrlList()} />
          <APIReport
            reportTableData={getReportTableData()}
            responseTimeChart={getResponseTimeData()}
            httpSuccessData={getHttpSuccessData()}
          />
        </div>
      )}
    </>
  );
};

export default APISummary;
