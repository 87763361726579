export const TYPES = {
  CHILD_TITLE: 'childTitle',
  SUB_TITLE: 'subTitle',
  LIST: 'list',
} as const;

type keys = keyof typeof TYPES;
type values = (typeof TYPES)[keys];

type TYPE = {
  type: values;
};

type TEXT = TYPE & {
  text: string;
  children?: never;
};

type LIST = TYPE & {
  text?: never;
  children: string[];
};

export type RECOMMENDATIONS_TYPE = TEXT | LIST;

export const RECOMMENDATIONS: { [key: string]: RECOMMENDATIONS_TYPE[] } = {
  'UI Recommendations': [
    // {
    //   text: 'Recommendations',
    //   type: TYPES.CHILD_TITLE,
    // },
    {
      text: 'Increase efficiency & minimize network payload',
      type: TYPES.SUB_TITLE,
    },
    {
      type: TYPES.LIST,
      children: [
        'Properly size images',
        'Reduce unused Javascript, CSS',
        'Remove duplicate Javascript modules from bundles',
        'Serve images in next-gen formats = Image formats like WebP and AVIF often provide better compression than PNG or JPEG, which means fatser downloads and less data consumption',
        'Defer offscreen images - Consier lazily loading the offscreen and hidden images',
        'Minify Javascript, CSS',
        'Avoid enormous packet loads',
        'Elimate render-blocking resources',
        'Serve static assets with an efficient cache policy',
      ],
    },
    {
      text: 'Best Practices',
      type: TYPES.CHILD_TITLE,
    },
    {
      type: TYPES.LIST,
      children: [
        'Specify alt attribute for images',
        'Image elements do not have explicit width and height - avoids layout shift',
        'Links should have a discernible name',
      ],
    },
    {
      text: 'Use CDN (Content Delivery Network)',
      type: TYPES.CHILD_TITLE,
    },
    {
      type: TYPES.LIST,
      children: [
        'A good CDN covers specific geographic regions of target customers',
        'CDN serves media faster and also alleviates pressure on the primary website servers',
      ],
    },
  ],
  'API Recommendations': [
    {
      text: 'Keep APIs light and fast',
      type: TYPES.SUB_TITLE,
    },
    {
      type: TYPES.LIST,
      children: [
        'Right size APIs to do work in under 500ms',
        'Do not mix APIs with heavy workloads such as reports in the same service',
        'Keep the request and response payloads light, under 100KB',
        'Compress response data',
        'Cache rarely changing data at the API gateways',
        'Paginate large datasets',
        'Implement throttling so large number of requests do not overwhelm the server',
        'Use non-blocking I/O and worker threads to scale http connections',
      ],
    },
    {
      text: 'Make services scale',
      type: TYPES.SUB_TITLE,
    },
    {
      type: TYPES.LIST,
      children: [
        'Design services as granular modules, so each service can scale independently',
        'Offload heavy work into separate threads/services',
        'Use messaging middleware to manage tasks in a reliable manner',
        'Make use of serverless to handle spikes and rarely used workloads',
        'Cache often used data',
        'Cache data at 2 levels in memory and local disk',
        'Avoid n/w disks to handle local files and logs',
        'Execute CPU and I/O work parallel to each other',
      ],
    },
    {
      text: 'Avoid database bottlenecks',
      type: TYPES.SUB_TITLE,
    },
    {
      type: TYPES.LIST,
      children: [
        'Keep the SQLs simple; avoid complex joins; limit joins to 4 tables',
        'Index the right fields, with the highest cardinality',
        'Avoid foreign keys and constraints',
        'Avoid ON UPDATE locks and ON DUPLICATE KEY statements',
        'Do not use triggers on tables',
        'Use READ_COMMITTED isolation always',
        'For autogenerated keys, use a high interval when setting up the sequence',
        'Leave 10% of memory on DB server free to avoid swap usage',
      ],
    },
  ],
};
