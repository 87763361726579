import { useCallback, useState } from 'react';
import Filters from '../Filters';
import IconTable from '../IconTable';

type TProps = {
  data: any[];
  columns: string[];
  scoreColumn: string;
  filterColumn: string;
  filterList: string[];
};

const ALL = 'All';

const FilterTable = (props: TProps) => {
  const { data, columns, scoreColumn, filterColumn, filterList } = props;
  const [selectedFilter, setSelectedFilter] = useState<string>(ALL);

  const getTableData = useCallback(() => {
    if (selectedFilter === ALL) {
      return data;
    }
    const filteredData = data.filter((item) => {
      return item[filterColumn] ? item[filterColumn].includes(selectedFilter) : false;
    });
    return filteredData;
  }, [selectedFilter]);

  return (
    <div>
      <Filters
        filterList={[ALL, ...filterList]}
        selectedFilter={selectedFilter}
        onChangeHandler={setSelectedFilter}
      />
      <IconTable columns={columns} scoreColumn={scoreColumn} data={getTableData()} />
    </div>
  );
};

export default FilterTable;
