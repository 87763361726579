import styles from './style.module.css';
import { PieChart, Pie, Cell, Label } from 'recharts';

type TProps = {
  value: number;
  barColor?: string;
  baseColor?: string;
  height?: number;
  borderRadius?: number;
};

const HalfDonut = (props: TProps) => {
  const { value, baseColor = '#c1e0c055', barColor = '#5bbb58' } = props;
  const data = [
    { name: 'Group A', value: value },
    { name: 'Group B', value: 100 - value },
  ];
  const COLORS = [barColor, baseColor, '#FFBB28', '#FF8042'];
  return (
    <div className={styles.container}>
      <PieChart width={700} height={250} style={{ margin: 'auto' }}>
        <Pie
          data={data}
          cx={340}
          cy={210}
          startAngle={180}
          endAngle={0}
          innerRadius={120}
          outerRadius={200}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label
            value={`${Math.round(value)}`}
            position="centerBottom"
            className={styles.center_label}
            style={{ fill: barColor }}
          />
        </Pie>
      </PieChart>
    </div>
  );
};

export default HalfDonut;
