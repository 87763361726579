import { Button } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';

import UserDetails from '../UserDetails';
import { useAppSelector } from '../../redux/app/hooks';
import { ROUTES } from '../../modules/constants';
import icon from '../../assets/images/Roadrunnr-logo.png';
import _ from 'lodash';
import styles from './header.module.css';
import mySite from '../../assets/images/my-site.png';
import publicSite from '../../assets/images/public-site.png';

const PATHS = [
  {
    label: 'My Sites',
    path: '/',
    icon: <img src={mySite} height={25} alt="my-site" />,
  },
  {
    label: 'Public Sites',
    path: '/site-history',
    icon: <img src={publicSite} height={25} alt="history" />,
  },
];

export const Header = () => {
  const userDetails = useAppSelector((state) => ({
    userEmail: state.auth.userEmail,
    userName: state.auth.userName,
  }));

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.logo_container}>
        <img src={icon} alt="Roadrunnr" />
      </div>
      <div className={styles.link__container}>
        {PATHS.map((item) => {
          return (
            <NavLink
              to={item.path}
              className={({ isActive }) => {
                return isActive ? styles.selected : '';
              }}
            >
              {item.icon}
              <p>{item.label}</p>
            </NavLink>
          );
        })}
      </div>
      <div className={styles.icon__container}>
        {userDetails.userEmail ? (
          <UserDetails userDetails={userDetails} />
        ) : (
          <Button
            variant="outlined"
            className={styles.login__button}
            onClick={() =>
              navigate(ROUTES.loginRoute, { state: { previousUrl: window.location.pathname } })
            }
          >
            Log in
          </Button>
        )}
      </div>
    </div>
  );
};
