// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBXbfILmNSKRKPOy3goFN72VJXWLMUOzXQ',
  authDomain: 'road-runner-ddb9f.firebaseapp.com',
  projectId: 'road-runner-ddb9f',
  storageBucket: 'road-runner-ddb9f.appspot.com',
  messagingSenderId: '315728855092',
  appId: '1:315728855092:web:8a66489fc0d57a7a6d4a61',
  measurementId: 'G-D60MH9ZR73',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
