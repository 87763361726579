import { memo, useMemo } from 'react';
import ErrorReport from '../common/ErrorReport';
import Loader from '../common/Loader';

import { AUDIT_ERROR_STATUS } from '../../modules/constants';
import styles from './basicreport.module.css';
import ScreenshotContainer from '../common/ScreenshotContainer';
import ChartContainer from '../common/ChartContainer';
import MetricsContainer from '../common/MetricsContainer';
import { TOP_FIVE_ENUM } from '../../modules/Utils/constants';
import { getErrorMessage } from '../../modules/Utils/utils';
import MetricReference from '../common/MetricReference';

type TProps = {
  basicReportData: any;
  isPdf?: boolean;
  reportStatus?: any;
};

export const BasicReport = memo((props: TProps) => {
  const { basicReportData, isPdf = false, reportStatus } = props;

  const extractedData = useMemo(() => {
    if (!basicReportData) {
      return null;
    }

    if (basicReportData === AUDIT_ERROR_STATUS) {
      return AUDIT_ERROR_STATUS;
    }

    let thumbnails =
      basicReportData.report.lighthouseResult?.audits?.['screenshot-thumbnails']?.details?.items;

    return {
      thumbnails,
      requestUrl: basicReportData.report.lighthouseResult.requestedUrl,
    };
  }, [basicReportData]);

  if (!extractedData) {
    return (
      <div className={`${styles.loader__container} ${styles.container}`}>
        <Loader loaderText="Analyzing Website" />
      </div>
    );
  }

  if (extractedData === AUDIT_ERROR_STATUS) {
    return (
      <div className={styles.error_container}>
        <ErrorReport
          message={reportStatus ? getErrorMessage(reportStatus.executionNote) : undefined}
        />
      </div>
    );
  }

  const { thumbnails, requestUrl } = extractedData;

  return (
    <div className={styles.container}>
      <ChartContainer requestUrl={requestUrl} report={basicReportData.report} />
      <br />
      {isPdf && <MetricReference />}
      <ScreenshotContainer
        thumbnails={thumbnails}
        mobile={true}
        speedIndex={
          basicReportData.report ? basicReportData.report[TOP_FIVE_ENUM.SPEED_INDEX] : null
        }
      />
      <br />
      <MetricsContainer reportData={basicReportData.report} isPdf={isPdf} />
      <br />
    </div>
  );
});
