import { useEffect, useState } from 'react';
import RadialBarChartComponent from '../RadialBarChart';
import styles from './style.module.css';
import { TOP_FIVE } from '../../../modules/Utils/constants';
import { getProgressBarColorObject } from '../../../modules/Utils/utils';
import HalfDonut from '../HalfDonut';
import { LEGEND } from './ChartUtils';

type TProps = {
  requestUrl: string;
  report: any;
};

const ChartContainer = (props: TProps) => {
  const { requestUrl, report } = props;
  const [topFive, setTopFive] = useState<any[]>([]);

  useEffect(() => {
    const list = TOP_FIVE.map((key: string) => {
      return report[key] ?? null;
    }).filter((item) => item);
    setTopFive(list);
  }, [report]);

  const { baseColor, barColor } = getProgressBarColorObject(report.performanceScore);

  return (
    <div>
      <div className={styles.basic__chart_section}>
        {requestUrl && (
          <div className={styles.basic__message_container}>
            <p className="title__text">
              Performance Report for <a href={requestUrl}>{requestUrl}</a> on Mobile
            </p>
          </div>
        )}
        <br />
        <div>
          <div className={`title__text_bold ${styles.chart_label}`}>Performance</div>
          <HalfDonut
            value={report.performanceScore * 100}
            baseColor={baseColor}
            barColor={barColor}
            height={20}
            borderRadius={0}
          />
        </div>
        <br />
        <div className={styles.top_five_container}>
          <div className={`title__text_bold ${styles.chart_label}`}>Top five metrics</div>
          <div className={styles.full_guage__container}>
            {topFive.map((audit) => {
              return (
                <RadialBarChartComponent
                  key={audit.id}
                  value={audit.score * 100}
                  label={audit.id.split('-').join(' ')}
                  size={100}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.chart_legend}>
          {LEGEND.map((item) => {
            const { barColor } = getProgressBarColorObject(item.value);
            return (
              <div className={styles.chart_legend_item}>
                <div className={styles.chart_legend_color} style={{ background: barColor }}></div>
                <div className={styles.chart_legend_content}>
                  <div className={styles.chart_legend_title}>{item.name}</div>
                  <div className={styles.chart_legent_range}>
                    <span className={styles.chart_legend_range_title}>Range: </span>
                    <span className={styles.chart_legend_range_value}>{item.range}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChartContainer;
