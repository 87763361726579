import { useCallback } from 'react';
import { formatNumber } from '../../../modules/Utils/utils';
import styles from './style.module.css';
import { Metric } from '../Metric/Metric';
import { TabODReport, PdfODReport } from '../OpportunityAndDiagnostic';
import { auditType } from '../../BasicReport/types';
import metricIcon from '../../../assets/images/metric-section.png';

type TProps = {
  reportData: any;
  isPdf?: boolean;
};

const MetricsContainer = (props: TProps) => {
  const { reportData, isPdf = false } = props;

  const getTopAudits = useCallback(() => {
    const tempAudits: any[] = Object.values(reportData.lighthouseResult.audits);
    const audits = (tempAudits as auditType[])
      .filter((audit) => {
        return audit.scoreDisplayMode === 'numeric' && audit.score > 0 && audit.displayValue;
      })
      .sort((a, b) => a.score - b.score);
    return audits.slice(0, 5);
  }, []);

  const getDisplayValue = useCallback((numericValue: number, numericType: string) => {
    if (numericType === 'millisecond') {
      return `${formatNumber(numericValue / 1000)} s`;
    }
    if (numericType === 'byte') {
      return `${formatNumber(numericValue / 1000)} KB`;
    }
    return formatNumber(numericValue);
  }, []);

  return (
    <div className={styles.metrics__container}>
      <div>
        <div className={styles.title_section}>
          <img src={metricIcon} height={20} alt="metrics" />
          <div className={styles.title__text}> metrics</div>
        </div>
        <div className={styles.metric__container}>
          {getTopAudits().map((audit) => {
            return (
              <Metric
                key={audit.id}
                value={audit.score * 100}
                title={audit.id.split('-').join(' ').toLocaleUpperCase()}
                displayValue={getDisplayValue(audit.numericValue, audit.numericUnit)}
              />
            );
          })}
        </div>
      </div>
      <div>
        {isPdf ? <PdfODReport reportData={reportData} /> : <TabODReport reportData={reportData} />}
      </div>
    </div>
  );
};

export default MetricsContainer;
