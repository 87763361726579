export const TOP_FIVE = [
  'total-blocking-time',
  'largest-contentful-paint',
  'cumulative-layout-shift',
  'first-contentful-paint',
  'speed-index',
];

export enum TOP_FIVE_ENUM {
  TOTAL_BLOCKING_TIME = 'total-blocking-time',
  LARGEST_CONTENTFUL_PAINT = 'largest-contentful-paint',
  CUMULATIVE_LAYOUT_SHIFT = 'cumulative-layout-shift',
  FIRST_CONTENTFUL_PAINT = 'first-contentful-paint',
  SPEED_INDEX = 'speed-index',
}

export const LOADING_TIPS = [
  'A one-second delay in website load time can result in a 7% reduction in conversion rates.',
  '70% of mobile pages take nearly 7 seconds for the visual content above the fold to display on the screen. [Source: Think with Google]',
  'Average user device costs less than 200 USD. [Source: International Data Corporation]',
  'Walmart saw a 1% increase in revenue for every 100ms improvement in pageload [Source: WPO Stats]',
];

export const LOADING_TIPS_TEMP = [
  'A one-second delay in website load time can result in a 7% reduction in conversion rates.',
  'Roadrunnr is gathering information',
  '70% of mobile pages take nearly 7 seconds for the visual content above the fold to display on the screen. [Source: Think with Google]',
  'Almost there! Roadrunnr is generating your report.',
  'Average user device costs less than 200 USD. [Source: International Data Corporation]',
  'Walmart saw a 1% increase in revenue for every 100ms improvement in pageload [Source: WPO Stats]',
];
