import styles from './style.module.css';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
type TProps = {
  urlList: any[];
};
const UrlList = (props: TProps) => {
  const { urlList } = props;

  const getTrimmedUrl = (url: string) => {
    if (url.length > 80) {
      const slicedUrl = url.slice(0, 80);
      return slicedUrl;
    }
    return url;
  };

  return (
    <div className={styles.url_conatainer}>
      <div className={'title__text'}> URLS ANALYSED</div>
      <div>
        {urlList.map((item) => {
          return (
            <div className={styles.url_item}>
              <LinkOutlinedIcon />
              <p>{getTrimmedUrl(item.url)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UrlList;
