export const ROUTES = {
  loginRoute: '/login',
};

export const BASE_URL = 'https://api.roadrunnerhq.com';
// export const BASE_URL = 'http://localhost:8080';
// export const BASE_URL = 'https://dev-api.roadrunnerhq.com';
// export const BASE_URL = 'http://174.138.123.238';
export const STATUS_INTERVAL = 10 * 1000;
export const AUDIT_DONE_STATUS = 'COMPLETED';
export const AUDIT_ERROR_STATUS = 'ERROR';
export const TRIGGER_SUBSCRIBE = 'triggerSubscribe';
export const TASK_NOT_INITIATED = 'TASK_NOT_INITIATED';

export const REPORTS = {
  BASIC: 'basic',
  ADVANCED: 'advanced',
  BACKEND_API: 'api',
} as const;

export const getAuditEndpoint = (url: string, uid: string): string => {
  try {
    const isUrlValid = new URL(url);
    return uid
      ? `/api/v1/audit?url=${isUrlValid.href}&uid=${uid}`
      : `/api/v1/audit?url=${isUrlValid.href}`;
  } catch (err) {
    return '';
  }
};

export const getReportStatusEndpoint = (id: string): string => {
  return `/api/v1/status?id=${id}`;
};

export const getReportDataEndpoint = (id: string): string => {
  return `/api/v1/report?id=${id}`;
};

export const subscribeReportEndPoint = (): string => {
  return '/api/v1/report/subscribe';
};

export const getTaskGroupEndpoint = (id: string): string => {
  return `/api/v1/history/report?taskGroupId=${id}`;
};

export const getSiteHistoryEndpoint = (): string => {
  return `/api/v1/history`;
};

export const getUserHistoryEndpoint = (uid: string): string => {
  return `/api/v1/history/user?uid=${uid}`;
};

export const getSiteHistoryEndpointWix = (): string => {
  return `/api/v1/history/wix`;
};

export const getPdfDownloadEndpoint = (basicId: string, apiId: string): string => {
  return `/api/v1/report/pdf?basic=${basicId}&api=${apiId}`;
};

export const getUrlCheckEndpoint = (url: string) => {
  return '/api/v1/site/check?url=' + url;
};

export const getSelectiveAuditEndpoint = () => {
  return '/api/v1/audit/selective';
};

export const getAuditProfileEndPoint = () => {
  return '/api/v1/audit/profile';
};
