import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AlertColor } from '@mui/material';

type ShowProps = {
  show: boolean;
};

export type InitialStateProps = {
  severity: AlertColor;
  description: React.ReactNode | string;
};

const initialState: ShowProps & InitialStateProps = {
  show: false,
  severity: 'success',
  description: '',
};

const snackBarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackBar: (state, action: PayloadAction<InitialStateProps>) => {
      state.show = true;
      state.severity = action.payload.severity;
      state.description = action.payload.description;
    },
    hideSnackBar: (state) => {
      state.show = false;
    },
  },
});

export const { showSnackBar, hideSnackBar } = snackBarSlice.actions;
export default snackBarSlice.reducer;
