import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import styles from './404.module.css';

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.page__404__parent}>
      <div className={styles.page_404_container} />
      <div className={styles.text__container}>
        <h3> Looks like you're lost </h3>
        <p> The page you are looking for is not available! </p>
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/', { replace: true });
          }}
        >
          Go to Home
        </Button>
      </div>
    </div>
  );
};
