import { Table, TableContainer, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import _ from 'lodash';
import byte from '../../../assets/images/byte.png';
import element from '../../../assets/images/element.png';
import milliseconds from '../../../assets/images/milliseconds.png';
import score from '../../../assets/images/score.png';
import unitless from '../../../assets/images/unitless.png';
import styles from './style.module.css';

type TProps = {
  summary: any;
};
const SimpleTable = (props: TProps) => {
  const { summary } = props;

  const getUnitIcon = (unit: string) => {
    switch (unit) {
      case 'byte':
        return byte;
      case 'element':
        return element;
      case 'millisecond':
        return milliseconds;
      case 'score':
        return score;
      case 'unitless':
      default:
        return unitless;
    }
  };
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Metric Id</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Min</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Max</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Mean</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Cumulative</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(summary).map((key, index) => {
            const item = summary[key];
            if (item.numericValueDistribution != undefined) {
              let min = Math.min(...item.numericValueDistribution);
              let max = Math.max(...item.numericValueDistribution);
              let mean =
                item.numericValueDistribution.reduce((a: any, b: any) => a + b, 0) /
                item.numericValueDistribution.length;
              let cumulative = item.numericValueDistribution.reduce((a: any, b: any) => a + b, 0);
              return (
                <TableRow key={index}>
                  <TableCell>
                    <div className={styles.metric_id_col}>
                      <img src={getUnitIcon(item.numericUnit)} height={18} alt="number" />
                      <div>
                        {item.key} ({item.numericUnit})
                      </div>
                    </div>
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{_.round(min).toFixed(2)} </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{_.round(max).toFixed(2)} </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{_.round(mean).toFixed(2)} </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    {_.round(cumulative).toFixed(2)}
                  </TableCell>
                </TableRow>
              );
            } else
              return (
                <TableRow key={index}>
                  <TableCell>
                    <div className={styles.metric_id_col}>
                      <img src={getUnitIcon('score')} height={18} alt="score" />
                      <div>{item.key} (score)</div>
                    </div>
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    {_.round(item.min).toFixed(2)}
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    {_.round(item.max).toFixed(2)}
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    {_.round(item.mean).toFixed(2)}
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    {_.round(item.cumulative).toFixed(2)}
                  </TableCell>
                </TableRow>
              );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
