import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const localStorageKey = 'road-runner';

export type AuthInitialState = {
  userEmail: string;
  userName: string;
  uid: string;
};

const initialState: AuthInitialState = {
  userEmail: '',
  userName: '',
  uid: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInitialAuth: (state) => {
      const isSetEarlier = localStorage.getItem(localStorageKey);
      const savedData = isSetEarlier
        ? (JSON.parse(isSetEarlier) as AuthInitialState)
        : ({} as AuthInitialState);
      state.userEmail = savedData.userEmail;
      state.userName = savedData.userName;
      state.uid = savedData.uid;
    },
    setAuth: (state, action: PayloadAction<AuthInitialState>) => {
      state.userEmail = action.payload.userEmail;
      state.userName = action.payload.userName;
      state.uid = action.payload.uid;
      localStorage.setItem(localStorageKey, JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.userEmail = '';
      state.userName = '';
      state.uid = '';
      localStorage.removeItem(localStorageKey);
    },
  },
});

export const { setAuth, logout, setInitialAuth } = authSlice.actions;
export default authSlice.reducer;
