import { Box, TextField } from '@mui/material';
import HistoryList from '../common/HistoryList';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { getSiteHistoryList } from '../../modules/Utils/HistoryReportUtils';
import Loader from '../common/Loader';
import ErrorReport from '../common/ErrorReport';
import styles from './style.module.css';
import { useParams } from 'react-router-dom';

type TProps = {
  onSelect: Function;
  selected: any;
};
const SearchSection = (props: TProps) => {
  const { onSelect, selected } = props;
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [data, setData] = useState([]);
  const { taskId } = useParams();

  useEffect(() => {
    getHistoryList();
  }, []);

  const getHistoryList = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await getSiteHistoryList();
      const data = response.data.reportsSummery;
      setData(data);
      if (data.length) {
        if (taskId) {
          const selectedData = _.find(data, (item) => {
            return item.taskStatus.taskGroupId == taskId;
          });
          if (selectedData) {
            onSelect(selectedData);
          } else {
            const data = {
              taskStatus: {
                taskGroupId: taskId,
              },
            };
            onSelect(data);
          }
        } else {
          onSelect(data[0]);
        }
      }
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getSiteHistoryData = useCallback(() => {
    if (!searchText) {
      return data;
    }
    return _.filter(data, (dataItem: any) => {
      return _.includes(dataItem.url, searchText);
    });
  }, [searchText, data]);
  if (isLoading) {
    return (
      <div className={styles.history_loader}>
        <Loader loaderText="Loading history data" />
      </div>
    );
  } else if (!data.length) {
    return <h1>No history available</h1>;
  } else if (isError) {
    return <ErrorReport />;
  }

  return (
    <div>
      <Box
        sx={{
          width: 500,
          maxWidth: '100%',
          marginBottom: '10px',
        }}
      >
        <TextField
          fullWidth
          label="Search"
          id="search-history"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Box>
      <HistoryList data={getSiteHistoryData()} onSelect={onSelect} selected={selected} />
    </div>
  );
};

export default SearchSection;
