import store from '../../redux/app/store';
import {
  setUrl,
  setBasicReport,
  setAdvancedReport,
  setBackendApiReport,
  markStatusAsDone,
  setTaskIds,
  taskIdType,
  setIsSubscribed,
  setTaskGroupId,
  setCreatedAt,
} from '../../redux/features/Reports/reportsSlice';
import {
  AUDIT_DONE_STATUS,
  getAuditEndpoint,
  getReportStatusEndpoint,
  getReportDataEndpoint,
  subscribeReportEndPoint,
  STATUS_INTERVAL,
  REPORTS,
  AUDIT_ERROR_STATUS,
  getPdfDownloadEndpoint,
  BASE_URL,
  getUrlCheckEndpoint,
  getSelectiveAuditEndpoint,
  getAuditProfileEndPoint,
  TASK_NOT_INITIATED,
} from '../constants';
import { axiosInstance } from '../../Axios';
import { displaySnackBar } from './showSnackBar';
import { parseQueryParams, stringifyQueryParams } from './utils';
import { AuthInitialState } from '../../redux/features/Auth/authSlice';
import { setStatusResponse } from '../../redux/features/Reports/reportStatusSlice';

type keys = keyof typeof REPORTS;
type values = (typeof REPORTS)[keys];

export const showErrorOnScreen = (description: string = 'Unable to process the request') => {
  displaySnackBar({
    severity: 'error',
    description,
  });
};

const setReportState = (reportType: values, data: JSON | string = AUDIT_ERROR_STATUS) => {
  if (reportType === REPORTS.BASIC) {
    store.dispatch(setBasicReport(data));
  } else if (reportType === REPORTS.ADVANCED) {
    store.dispatch(setAdvancedReport(data));
  } else {
    store.dispatch(setBackendApiReport(data));
  }
};

const getReportData = (id: string, reportType: values) => {
  const endpoint = getReportDataEndpoint(id);
  axiosInstance
    .get(endpoint)
    .then((res) => {
      setReportState(reportType, res.data);
    })
    .catch((err) => {
      showErrorOnScreen();
      setReportState(reportType);
      console.error(err);
    });
};

export const getReportStatus = async (id: string, reportType: values) => {
  const endpoint = getReportStatusEndpoint(id);
  const reportStatusInterval = setInterval(async () => {
    try {
      const res = await axiosInstance.get(endpoint);
      store.dispatch(
        setStatusResponse({
          reportStatus: res.data ? res.data.status : null,
          reportType,
        })
      );
      if (res.data.status.status.toUpperCase() === AUDIT_DONE_STATUS.toUpperCase()) {
        clearInterval(reportStatusInterval);
        store.dispatch(setTaskGroupId(res.data.status.taskGroupId));
        store.dispatch(setCreatedAt(res.data.status.createdAt));
        getReportData(id, reportType);
      } else if (res.data.status.status.toUpperCase() === AUDIT_ERROR_STATUS.toUpperCase()) {
        throw new Error(`Received the status as ${AUDIT_ERROR_STATUS}`);
      }
    } catch (err) {
      clearInterval(reportStatusInterval);
      setReportState(reportType);
      showErrorOnScreen();
      console.error(err);
    }
  }, STATUS_INTERVAL);
  try {
    const res = await axiosInstance.get(endpoint);
    store.dispatch(
      setStatusResponse({
        reportStatus: res.data ? res.data.status : null,
        reportType,
      })
    );
    if (res.data.status.status.toUpperCase() === AUDIT_DONE_STATUS.toUpperCase()) {
      clearInterval(reportStatusInterval);
      store.dispatch(setTaskGroupId(res.data.status.taskGroupId));
      store.dispatch(setCreatedAt(res.data.status.createdAt));
      getReportData(id, reportType);
    } else if (res.data.status.status.toUpperCase() === AUDIT_ERROR_STATUS.toUpperCase()) {
      throw new Error(`Received the status as ${AUDIT_ERROR_STATUS}`);
    }
  } catch (err) {
    clearInterval(reportStatusInterval);
    setReportState(reportType);
    showErrorOnScreen();
    console.error(err);
  }
};

const storeTaskIds = (data: taskIdType) => {
  store.dispatch(setTaskIds(data));
};

export const analyzeWebsite = async (url: string, uid: string) => {
  const endpoint = getAuditEndpoint(url, uid);
  if (!endpoint) {
    showErrorOnScreen('URL provided is invalid');
    return;
  }
  store.dispatch(setUrl(url));
  try {
    const res = await axiosInstance.get(endpoint);
    const { simpleReportId, advancedReportId, backendReportId } = res.data;
    storeTaskIds({
      [REPORTS.BASIC]: simpleReportId,
      [REPORTS.ADVANCED]: advancedReportId,
      [REPORTS.BACKEND_API]: backendReportId,
    });
    getReportStatus(simpleReportId, REPORTS.BASIC);
    getReportStatus(advancedReportId, REPORTS.ADVANCED);
    getReportStatus(backendReportId, REPORTS.BACKEND_API);
  } catch (err) {
    showErrorOnScreen();
    store.dispatch(markStatusAsDone());
    console.error(err);
  }
};

const generateLink = (): string => {
  const storeData = store.getState();
  const queryString = stringifyQueryParams({
    url: storeData.reports.url,
    [REPORTS.BASIC]: storeData.reports.taskId[REPORTS.BASIC],
    [REPORTS.ADVANCED]: storeData.reports.taskId[REPORTS.ADVANCED],
    [REPORTS.BACKEND_API]: storeData.reports.taskId[REPORTS.BACKEND_API],
  });
  return `${window.location.origin}/${queryString}`;
};

export const generateAndCopyLink = () => {
  const link = generateLink();
  navigator.clipboard.writeText(link);
};

export const subscribe = async (authDetails?: AuthInitialState) => {
  const {
    auth,
    reports: { taskId },
  } = store.getState();

  try {
    const endpoint = subscribeReportEndPoint();
    await axiosInstance.post(endpoint, {
      uid: authDetails?.uid || auth.uid,
      taskId: taskId[REPORTS.BASIC],
      email: authDetails?.userEmail || auth.userEmail,
      referenceUrl: generateLink(),
    });
    store.dispatch(setIsSubscribed());
  } catch (err) {
    displaySnackBar({
      severity: 'error',
      description: 'Unable to subscribe',
    });
  }
};

export const getReportFromQueryParams = () => {
  const parsedQueryParams = parseQueryParams(window.location.search.substring(1));
  if (
    parsedQueryParams.url &&
    parsedQueryParams[REPORTS.BASIC] &&
    parsedQueryParams[REPORTS.ADVANCED] &&
    parsedQueryParams[REPORTS.BACKEND_API]
  ) {
    store.dispatch(setUrl(parsedQueryParams.url));
    storeTaskIds({
      [REPORTS.BASIC]: parsedQueryParams[REPORTS.BASIC],
      [REPORTS.ADVANCED]: parsedQueryParams[REPORTS.ADVANCED],
      [REPORTS.BACKEND_API]: parsedQueryParams[REPORTS.BACKEND_API],
    });
    getReportStatus(parsedQueryParams[REPORTS.BASIC], REPORTS.BASIC);
    getReportStatus(parsedQueryParams[REPORTS.ADVANCED], REPORTS.ADVANCED);
    getReportStatus(parsedQueryParams[REPORTS.BACKEND_API], REPORTS.BACKEND_API);
  }
};

export const getPdfReport = async (basicReportId: string, apiReportId: string) => {
  const endpoint = getPdfDownloadEndpoint(basicReportId, apiReportId);
  const link = document.createElement('a');
  link.setAttribute('href', BASE_URL + endpoint);
  link.setAttribute('download', basicReportId + '.pdf');
  link.target = '_blank';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const fetchRoutesAndApis = async (url: string) => {
  try {
    store.dispatch(setUrl(url));
    //setting advanced report status as not initiated
    setReportState(REPORTS.ADVANCED, TASK_NOT_INITIATED);
    setReportState(REPORTS.BACKEND_API, TASK_NOT_INITIATED);

    const endpoint = getUrlCheckEndpoint(url);
    const response: any = await axiosInstance.get(endpoint);
    const basicReport = response?.data?.report;
    if (basicReport && basicReport?.report?.error) {
      throw { message: basicReport.report.error };
    }
    //TODO: check if task id needs to be updated
    updateBasicReport(basicReport.task, basicReport.simpleVersion);
    return response.data;
  } catch (e: any) {
    setReportState(REPORTS.BASIC);
    showErrorOnScreen();
    const error = e.response?.data?.error ?? e.message ?? 'Something went wrong. please try again';
    store.dispatch(
      setStatusResponse({
        reportStatus: { executionNote: error },
        reportType: REPORTS.BASIC,
      })
    );
    throw error;
  }
};

export const fetchAdditionalRoutes = async (request: any) => {
  try {
    const endpoint = getAuditProfileEndPoint();
    const res = await axiosInstance.post(endpoint, request);
    return res.data;
  } catch (err) {
    showErrorOnScreen('Error fetching routes');
    throw err;
  }
};

const updateBasicReport = (task: any, reportData: any) => {
  store.dispatch(
    setStatusResponse({
      reportStatus: task ?? null,
      reportType: REPORTS.BASIC,
    })
  );
  setReportState(REPORTS.BASIC, { report: reportData } as any);
};

export const analyzeSelectedUrls = async (request: any, basicReport: any) => {
  const endpoint = getSelectiveAuditEndpoint();
  if (!request.host) {
    showErrorOnScreen('URL provided is invalid');
    return;
  }
  store.dispatch(setUrl(request.host));
  try {
    const res = await axiosInstance.post(endpoint, request);
    const { simpleReportId, advancedReportId, backendReportId } = res.data;
    storeTaskIds({
      [REPORTS.BASIC]: simpleReportId,
      [REPORTS.ADVANCED]: advancedReportId,
      [REPORTS.BACKEND_API]: backendReportId,
    });

    const { status } = basicReport?.task;
    if (status === AUDIT_DONE_STATUS && simpleReportId === basicReport.task?.id) {
      updateBasicReport(basicReport.task, basicReport.simpleVersion);
    } else {
      getReportStatus(simpleReportId, REPORTS.BASIC);
    }

    getReportStatus(advancedReportId, REPORTS.ADVANCED);
    getReportStatus(backendReportId, REPORTS.BACKEND_API);
  } catch (err) {
    showErrorOnScreen();
    store.dispatch(markStatusAsDone());
    console.error(err);
  }
};
