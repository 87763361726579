import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Login from '../components/Login';
import Layout from '../components/Layout';
import PageNotFound from '../components/404';

import { ROUTES } from '../modules/constants';
import { useAppSelector } from '../redux/app/hooks';
import ErrorBoundary from '../components/ErrorBoundary';
import SiteHistoryLayout from '../components/SiteHistoryLayout';
import HistoryTable from '../components/HistoryTable';
import PdfReport from '../components/PdfReport';
import AuthWrapper from './AuthWrapper';

export const AppRoutes = () => {
  const userEmail = useAppSelector((state) => state.auth.userEmail);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ErrorBoundary>
              <AuthWrapper component={<Layout />} />
            </ErrorBoundary>
          }
        />
        <Route
          path="/site-history/:taskId?"
          element={
            <ErrorBoundary>
              <AuthWrapper component={<SiteHistoryLayout />} />
            </ErrorBoundary>
          }
        />
        <Route
          path="/top-history"
          element={
            <ErrorBoundary>
              <HistoryTable />
            </ErrorBoundary>
          }
        />
        <Route
          path="/pdf-report"
          element={
            <ErrorBoundary>
              <PdfReport />
            </ErrorBoundary>
          }
        />
        {/* Creating the login route only when the user details is not available */}
        {/* {!userEmail ? ( */}
        <Route
          path={ROUTES.loginRoute}
          element={
            <ErrorBoundary>
              <AuthWrapper component={<Login />} />
            </ErrorBoundary>
          }
        />
        {/* ) : null} */}
        <Route
          path="*"
          element={
            <ErrorBoundary>
              <PageNotFound />
            </ErrorBoundary>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
