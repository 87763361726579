import styles from './style.module.css';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';

type TProps = {
  routes: string[];
  urls: string[];
};

const Confirmation = (props: TProps) => {
  const { routes, urls } = props;

  return (
    <div>
      <div className={styles.wizard__title}>Confirmation</div>
      <div className={styles.wizard__subtitle}>Selected api routes</div>
      <div>
        {routes.map((item: any) => (
          <div className={styles.wizard_list_item}>
            <AdjustOutlinedIcon className={styles.wizard_list_icons} />
            <div className={styles.wizard_list_label}>{item.name}</div>
          </div>
        ))}
        {!routes.length && (
          <div className={styles.wizard_empty_confirmation_msg}>No routes selected to analyse</div>
        )}
      </div>
      <div className={styles.wizard__subtitle}>Selected page urls</div>
      <div>
        {urls.map((item: any) => (
          <div className={styles.wizard_list_item}>
            <AdjustOutlinedIcon className={styles.wizard_list_icons} />
            <div className={styles.wizard_list_label}>{item.url}</div>
          </div>
        ))}
        {!urls.length && (
          <div className={styles.wizard_empty_confirmation_msg}>No urls selected to analyse</div>
        )}
      </div>
    </div>
  );
};

export default Confirmation;
