import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { useCallback, useState } from 'react';
import { R_TYPES, getDataObjectList, COLUMNS, FIELDS } from './ODUtils';
import _ from 'lodash';
import FilterTable from '../FilterTable';
import { TOP_FIVE } from '../../../modules/Utils/constants';
import opportunities from '../../../assets/images/opportunities.png';
import diagnostics from '../../../assets/images/diagnostic-tabs.png';

type Tprops = {
  reportData: any;
  url?: string;
};

const TabODReport = (props: Tprops) => {
  const { reportData, url } = props;
  const [selectedTab, setSelectedTab] = useState(R_TYPES.OPPORTUNITY);

  const getData = useCallback(
    (rType: R_TYPES) => {
      return getDataObjectList(reportData, rType);
    },
    [reportData]
  );

  return (
    <div key={url}>
      <TabContext value={selectedTab}>
        <TabList
          onChange={(event, value) => {
            setSelectedTab(value);
          }}
          aria-label="Select Site Version"
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'start',
            },
          }}
        >
          <Tab
            iconPosition="start"
            label={R_TYPES.OPPORTUNITY}
            value={R_TYPES.OPPORTUNITY}
            icon={<img src={opportunities} height={23} alt="opportunities" />}
          />
          <Tab
            iconPosition="start"
            label={R_TYPES.DIAGNOSTIC}
            value={R_TYPES.DIAGNOSTIC}
            icon={<img src={diagnostics} height={20} alt="diagnostics" />}
          />
        </TabList>
        {_.values(R_TYPES).map((tabName) => (
          <TabPanel value={tabName} style={{ padding: '0px' }}>
            <FilterTable
              data={getData(tabName)}
              columns={COLUMNS}
              scoreColumn={FIELDS.SCORE}
              filterColumn={FIELDS.METRIC_KEYS}
              filterList={TOP_FIVE}
            />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default TabODReport;
