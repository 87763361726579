import { useEffect, useState } from 'react';
import { getSiteHistoryListWix } from '../../modules/Utils/HistoryReportUtils';
import Loader from '../common/Loader';
import ErrorReport from '../common/ErrorReport';
import styles from './style.module.css';
import DesktopTableComponent from './DesktopTableComponent';
import MobileCardListContainer from './MobileCardListContainer';

const HistoryTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    loadHistoryData();
  }, []);

  const loadHistoryData = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await getSiteHistoryListWix();
      if (response.data) {
        const data: any = [...response.data.fast, ...response.data.average, ...response.data.slow];
        setData(data);
      }
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.center__container}>
        <Loader loaderText="Loading history" />
      </div>
    );
  } else if (isError) {
    return (
      <div className={styles.center__container}>
        <ErrorReport />
      </div>
    );
  }

  return window.matchMedia('(max-width: 600px)').matches ? (
    <MobileCardListContainer data={data} />
  ) : (
    <DesktopTableComponent data={data} />
  );
};

export default HistoryTable;
