import { memo, useCallback, useMemo, useState } from 'react';
import { LayersOutlined } from '@mui/icons-material';

import BasicReport from '../../BasicReport';
import AdvancedReports from '../../AdvancedReports';

import { useAppSelector } from '../../../redux/app/hooks';
import { AUDIT_ERROR_STATUS, REPORTS } from '../../../modules/constants';

import styles from './styles.module.css';
import VerticalLoader from '../../common/VerticalLoader';
import AdvancedIcon from '../../../assets/images/advanced-tabs.png';
import BasicIcon from '../../../assets/images/basic-report.png';

type TProps = {
  data: any;
};
const ReportsContainer = memo((props: TProps) => {
  const { data } = props;
  const [isBasicReportSelected, setIsBasicReportSelected] = useState(true);
  const email = useAppSelector((state) => ({
    userEmail: state.auth.userEmail,
  }));

  const getAdvancedData = useCallback(() => {
    return {
      userEmail: email.userEmail,
      advancedReport: data[REPORTS.ADVANCED],
      apiReport: data[REPORTS.BACKEND_API],
    };
  }, [data]);

  const advancedReportData = useMemo(() => {
    if (!data[REPORTS.ADVANCED]) {
      return null;
    }
    if (data[REPORTS.ADVANCED] === AUDIT_ERROR_STATUS) {
      return data[REPORTS.ADVANCED];
    }
    const advancedReport = data[REPORTS.ADVANCED].report.reduce(
      (map: { [key: string]: any }, obj: { url: string }) => {
        map[obj.url] = obj;
        return map;
      },
      {}
    );

    return advancedReport;
  }, [data[REPORTS.ADVANCED]]);

  return (
    <>
      <div className={styles.title__container}>
        <LayersOutlined sx={{ color: 'var(--primary-color-light-variant)' }} />
        <p> Get valuable insights of the website experience </p>
        <div className={styles.spacer__div} />
      </div>

      <div className={styles.report__title__container}>
        <div
          className={`${styles.report__title__div} ${
            isBasicReportSelected ? styles.report__title__div_selected : ''
          }`}
          onClick={() => setIsBasicReportSelected(true)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              setIsBasicReportSelected(true);
            }
          }}
        >
          <span>
            <img src={BasicIcon} height={18} alt="basic" />
          </span>
          <span className={isBasicReportSelected ? styles.report__selected : styles.report__text}>
            Basic Report
          </span>
        </div>
        <div
          className={`${styles.report__title__div} ${
            !isBasicReportSelected ? styles.report__title__div_selected : ''
          }`}
          onClick={() => setIsBasicReportSelected(false)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              setIsBasicReportSelected(false);
            }
          }}
        >
          <div className={styles.advanced__tab_conatiner}>
            <span>
              <img src={AdvancedIcon} height={30} alt="advanced" />
            </span>
            <span
              className={!isBasicReportSelected ? styles.report__selected : styles.report__text}
            >
              Advanced Report
            </span>
            {advancedReportData !== AUDIT_ERROR_STATUS && !advancedReportData && <VerticalLoader />}
          </div>
        </div>
      </div>

      <div className={styles.report__details}>
        {isBasicReportSelected ? (
          <BasicReport basicReportData={data[REPORTS.BASIC]} />
        ) : (
          <AdvancedReports data={getAdvancedData()} />
        )}
      </div>
    </>
  );
});

export default ReportsContainer;
