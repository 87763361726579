import styles from './style.module.css';
import { PieChart, ResponsiveContainer, Pie, Label } from 'recharts';
import { basename } from 'path';

type TProps = {
  value: number;
  barColor?: string;
  baseColor?: string;
  height?: number;
  borderRadius?: number;
};

const FullDonut = (props: TProps) => {
  const {
    value,
    baseColor = '#c1e0c055',
    barColor = '#5bbb58',
    height = 5,
    borderRadius = 5,
  } = props;
  const data = [
    { name: 'Group A', value: value, fill: barColor },
    { name: 'Group B', value: 100 - value, fill: baseColor },
  ];
  const style = {
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  };
  const COLORS = [barColor, baseColor, '#FFBB28', '#FF8042'];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={18}
          fill="#8884d8"
          paddingAngle={0}
        >
          <Label
            value={`${Math.round(value)}`}
            position="center"
            className={styles.center_label}
            style={{ fill: barColor }}
          />
        </Pie>
        {/* <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label /> */}
      </PieChart>
      {/* <RadialBarChart cx="50%" cy="50%" innerRadius="80%" outerRadius="90%" barSize={5} data={data}>
          <RadialBar
            //minAngle={15}
            label={{ position: 'center', fill: barColor }}
            background
            // clockWise
            dataKey="value"
          />
         
        </RadialBarChart> */}
    </ResponsiveContainer>
  );
};

export default FullDonut;
