import { memo, useEffect, useState } from 'react';

import LoadingTips from '../../LoadingTips';
import Reports from '../../Reports';
import { useAppSelector } from '../../../redux/app/hooks';
import { analyzeWebsite } from '../../../modules/Utils/reportsUtil';
import { AnalyzeReportHomeProps } from './types';

import styles from './anaylzereporthome.module.css';
import SelectionModal from '../SelectionModal';

export const AnalyzeReportHome = memo(({ url }: AnalyzeReportHomeProps) => {
  const reportData = useAppSelector((state) => state.reports);
  const user = useAppSelector((state) => state.auth);
  const [pageUrl, setPageUrl] = useState(url);

  useEffect(() => {
    setPageUrl(reportData.url);
  }, [reportData.url]);

  useEffect(() => {
    if (url) {
      setPageUrl(url);
      analyzeWebsite(url, user?.uid);
    }
  }, [url]);

  return (
    <div className={styles.container}>
      <div className={styles.generate__text}> Generate performance report </div>

      <SelectionModal url={pageUrl} uid={user?.uid} setPageUrl={setPageUrl} />

      {reportData.status === 0 || reportData.status === -1 ? (
        <LoadingTips status={reportData.status} />
      ) : (
        <Reports />
      )}
    </div>
  );
});
