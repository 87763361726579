import { useEffect, useState } from 'react';
import { REPORTS } from '../../modules/constants';
import { getSiteHistoryReport, getTaskGroup } from '../../modules/Utils/HistoryReportUtils';
import _ from 'lodash';
import ErrorReport from '../common/ErrorReport';
import Loader from '../common/Loader';
import ReportsContainer from '../common/ReportContainer';
import styles from './style.module.css';
import { useParams } from 'react-router-dom';

type TProps = {
  data: any;
};

const HistoryReportContainer = (props: TProps) => {
  const { data } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reportGroup, setReportGroup] = useState({});
  const { taskId } = useParams();

  useEffect(() => {
    if (taskId) {
      updateState();
    }
  }, [taskId]);

  const updateState = async () => {
    try {
      setIsError(false);
      setIsLoading(true);
      setReportGroup({});
      const taskGroup = await updateTaskGroup();
      await updateReportGroup(taskGroup);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTaskGroup = async () => {
    const taskGroupData = await getTaskGroup(taskId);
    if (taskGroupData) {
      const tempTaskGroup = {
        [REPORTS.BASIC]: _.find(taskGroupData.taskGroup, { taskType: 'SIMPLE' }),
        [REPORTS.ADVANCED]: _.find(taskGroupData.taskGroup, { taskType: 'ADVANCED' }),
        [REPORTS.BACKEND_API]: _.find(taskGroupData.taskGroup, { taskType: 'BACKEND' }),
      };
      return tempTaskGroup;
    }
  };

  const updateReportGroup = async (taskGroup: any) => {
    const tempReportGroup = await getSiteHistoryReport(taskGroup);
    setReportGroup(tempReportGroup);
  };

  if (isLoading) {
    return (
      <div className={styles.history_loader}>
        <Loader loaderText="Loading data" />
      </div>
    );
  }
  if (isError) {
    return <ErrorReport />;
  }
  if (!data) return null; // handle no data case

  return (
    <div key={data.id} className={styles.history__report}>
      <ReportsContainer data={reportGroup} />
    </div>
  );
};

export default HistoryReportContainer;
