import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AUDIT_ERROR_STATUS, REPORTS } from '../../../modules/constants';

export type taskIdType = {
  [REPORTS.BASIC]: string;
  [REPORTS.ADVANCED]: string;
  [REPORTS.BACKEND_API]: string;
};

type ReportsType = {
  url: string;
  status: number;
  taskId: taskIdType;
  [REPORTS.BASIC]: any;
  [REPORTS.ADVANCED]: any;
  [REPORTS.BACKEND_API]: any;
  isSubscribed: boolean;
  taskGroupId: string;
  createdAt: string;
};

let status = -1;

const initialState: ReportsType = {
  url: '',
  status,
  taskId: {
    [REPORTS.BASIC]: '',
    [REPORTS.ADVANCED]: '',
    [REPORTS.BACKEND_API]: '',
  },
  [REPORTS.BASIC]: null,
  [REPORTS.ADVANCED]: null,
  [REPORTS.BACKEND_API]: null,
  isSubscribed: false,
  taskGroupId: '',
  createdAt: '',
};

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
      status = 0;
      state.status = status;
      state[REPORTS.BASIC] = null;
      state[REPORTS.ADVANCED] = null;
      state[REPORTS.BACKEND_API] = null;
      state.isSubscribed = false;
      state.taskGroupId = '';
      state.createdAt = '';
    },
    setTaskIds: (state, action: PayloadAction<taskIdType>) => {
      state.taskId[REPORTS.BASIC] = action.payload[REPORTS.BASIC];
      state.taskId[REPORTS.ADVANCED] = action.payload[REPORTS.ADVANCED];
      state.taskId[REPORTS.BACKEND_API] = action.payload[REPORTS.BACKEND_API];
    },
    setBasicReport: (state, action: PayloadAction<any>) => {
      state[REPORTS.BASIC] = action.payload;
      status += 1;
      state.status = status;
    },
    setAdvancedReport: (state, action: PayloadAction<any>) => {
      state[REPORTS.ADVANCED] = action.payload;
      status += 1;
      state.status = status;
    },
    setBackendApiReport: (state, action: PayloadAction<any>) => {
      state[REPORTS.BACKEND_API] = action.payload;
      status += 1;
      state.status = status;
    },
    markStatusAsDone: (state) => {
      status = 3;
      state.status = 3;
      state[REPORTS.BASIC] = AUDIT_ERROR_STATUS;
      state[REPORTS.ADVANCED] = AUDIT_ERROR_STATUS;
      state[REPORTS.BACKEND_API] = AUDIT_ERROR_STATUS;
    },
    setIsSubscribed: (state) => {
      state.isSubscribed = true;
    },
    reset: (state) => {
      state.url = '';
      status = -1;
      state.status = status;
      state[REPORTS.BASIC] = null;
      state[REPORTS.ADVANCED] = null;
      state[REPORTS.BACKEND_API] = null;
      state.isSubscribed = false;
    },
    setTaskGroupId: (state, action: PayloadAction<any>) => {
      state.taskGroupId = action.payload;
    },
    setCreatedAt: (state, action: PayloadAction<any>) => {
      state.createdAt = action.payload;
    },
  },
});

export const {
  setUrl,
  setBasicReport,
  setBackendApiReport,
  setAdvancedReport,
  markStatusAsDone,
  reset,
  setTaskIds,
  setIsSubscribed,
  setTaskGroupId,
  setCreatedAt,
} = reportSlice.actions;
export default reportSlice.reducer;
