export const METRIC_REF = [
  {
    name: 'Total Blocking Time',
    description:
      'Sum of all time periods between FCP and Time to interactive, when task length exceeds 50ms, expressed in milliseconds',
    weight: '30%',
  },
  {
    name: 'Largest Contentful Paint',
    description:
      'Largest Contentful Paint marks the time at which the largest text or image is painted',
    weight: '25%',
  },
  {
    name: 'Cumulative Layout Shift',
    description:
      'Cumulative Layout Shift measures the movement of visible elements within the viewport',
    weight: '25%',
  },
  {
    name: 'First Contentful Paint',
    description:
      'First Contentful Paint marks the time at which the first text or image is painted',
    weight: '10%',
  },
  {
    name: 'Speed Index',
    description: 'Speed Index shows how quickly the contents of a page are visibly populated',
    weight: '10%',
  },
];
