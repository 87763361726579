import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type TProps = {
  selectedIndex: number;
  onChangeHandler: Function;
  urlList: any[];
};
const UrlSelector = (props: TProps) => {
  const { selectedIndex, onChangeHandler, urlList } = props;
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Select Url</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedIndex}
        label="Select Url"
        className="url-selector"
        onChange={(event, child) => {
          onChangeHandler(event.target.value);
        }}
      >
        {urlList.map((value, index) => {
          return (
            <MenuItem
              key={index}
              value={index}
              style={{ whiteSpace: 'normal', wordBreak: 'break-all', maxWidth: '870px' }}
            >
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default UrlSelector;
