import { useCallback } from 'react';
import { R_TYPES, getDataObjectList, COLUMNS, FIELDS } from './ODUtils';
import _ from 'lodash';
import FilterTable from '../FilterTable';
import { TOP_FIVE } from '../../../modules/Utils/constants';
import styles from './style.module.css';

type Tprops = {
  reportData: any;
  url?: string;
};

const PdfODReport = (props: Tprops) => {
  const { reportData, url } = props;

  const getData = useCallback(
    (rType: R_TYPES) => {
      return getDataObjectList(reportData, rType);
    },
    [reportData]
  );

  return (
    <div key={url}>
      {_.values(R_TYPES).map((tabName) => (
        <div>
          <div className={styles.metric__sub_title}>{tabName}</div>
          <FilterTable
            data={getData(tabName)}
            columns={COLUMNS}
            scoreColumn={FIELDS.SCORE}
            filterColumn={FIELDS.METRIC_KEYS}
            filterList={TOP_FIVE}
          />
        </div>
      ))}
    </div>
  );
};

export default PdfODReport;
