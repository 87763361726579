import { useCallback, useRef, useState } from 'react';
import styles from './style.module.css';
import { Button } from '@mui/material';
import RouteSelector from './RouteSelector';
import UIUrlSelector from './UIUrlselector';
import Confirmation from './Confirmation';
import { analyzeSelectedUrls, fetchAdditionalRoutes } from '../../../modules/Utils/reportsUtil';

type TProps = {
  routes: string[];
  urls: string[];
  onClose: any;
  host: string;
  uid: string;
  basicReport: any;
  selectedUrlIndexes: number[];
  setSelectedUrlIndexes: Function;
  selectedRouteIndexes: number[];
  setSelectedRoutesIndexes: Function;
  additionalError: string;
  additionalUrls: any[];
};

const Wizard = (props: TProps) => {
  const {
    routes,
    urls,
    onClose,
    host,
    uid,
    basicReport,
    selectedUrlIndexes,
    setSelectedUrlIndexes,
    selectedRouteIndexes,
    setSelectedRoutesIndexes,
    additionalUrls,
    additionalError,
  } = props;

  const [selectedAdditionalUrlIndexes, setSelectedAdditionalUrlsIndexes] = useState<number[]>([]);

  const ref = useRef(null);
  const [step, setStep] = useState(1); //Steps are 1 - ui-urls, 2 - api-routes, 3- confirmation

  const getSelectedList = (itemList: any[], selectedIndexList: number[]) => {
    const filteredList = itemList.filter((item: any, itemIndex: number) =>
      selectedIndexList.includes(itemIndex)
    );
    return filteredList;
  };

  const getSelectedRouteList = () => {
    const defaultSelectedRoutes = getSelectedList(routes, selectedRouteIndexes);
    const additionalSelectedRoutes = getSelectedList(additionalUrls, selectedAdditionalUrlIndexes);
    return [...defaultSelectedRoutes, ...additionalSelectedRoutes];
  };

  const getRequest = () => {
    const request = {
      host: host,
      uid: uid,
      selectedRoutes: getSelectedRouteList(),
      selectedUrlList: getSelectedList(urls, selectedUrlIndexes),
      simpleTaskId: basicReport?.task?.id,
    };
    return request;
  };

  const onConfirmation = () => {
    const request = getRequest();
    analyzeSelectedUrls(request, basicReport);
    onClose();
  };

  const getWizardContent = useCallback(() => {
    switch (step) {
      case 1:
        return (
          <UIUrlSelector
            urls={urls}
            selectedindexList={selectedUrlIndexes}
            onSelectionChange={setSelectedUrlIndexes}
          />
        );
      case 2:
        return (
          <RouteSelector
            routes={routes}
            selectedindexList={selectedRouteIndexes}
            onSelectionChange={setSelectedRoutesIndexes}
            additionalError={additionalError}
            additionalUrls={additionalUrls}
            selectedAdditionalUrlIndexes={selectedAdditionalUrlIndexes}
            setSelectedAdditionalUrlsIndexes={setSelectedAdditionalUrlsIndexes}
          />
        );
      case 3:
        return (
          <Confirmation
            routes={getSelectedRouteList()}
            urls={getSelectedList(urls, selectedUrlIndexes)}
          />
        );
    }
  }, [
    step,
    selectedRouteIndexes,
    selectedUrlIndexes,
    additionalError,
    additionalUrls,
    selectedAdditionalUrlIndexes,
  ]);

  return (
    <div className={styles.wizard__container} ref={ref}>
      <div className={styles.wizard_content}>{getWizardContent()}</div>
      <div className={styles.wizard_controls}>
        <Button onClick={onClose} className={styles.sm_cancel__button}>
          Cancel
        </Button>
        {[2, 3].includes(step) && (
          <Button
            variant="outlined"
            onClick={() => setStep(step - 1)}
            className={styles.sm_outline__button}
          >
            Back
          </Button>
        )}
        {[1, 2].includes(step) && (
          <Button
            variant="outlined"
            onClick={() => setStep(step + 1)}
            className={styles.sm_outline__button}
          >
            Next
          </Button>
        )}
        {[3].includes(step) && (
          <Button onClick={onConfirmation} className={styles.sm_confirm__button}>
            Confirm
          </Button>
        )}
      </div>
    </div>
  );
};

export default Wizard;
