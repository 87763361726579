import { DesktopMacOutlined, PhoneIphoneOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import _ from 'lodash';
import ChartContainer from '../../common/ChartContainer';
import { useCallback, useState } from 'react';
import ScreenshotContainer from '../../common/ScreenshotContainer';
import MetricsContainer from '../../common/MetricsContainer';
import { TOP_FIVE_ENUM } from '../../../modules/Utils/constants';

type TProps = {
  reportData: any;
  url: string;
};

enum DEVICES {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

const ReportConatiner = (props: TProps) => {
  const { reportData, url } = props;
  const [selectedTab, setSelectedTab] = useState(DEVICES.MOBILE);

  const getThumbnailList = useCallback(
    (device: DEVICES) => {
      const deviceData = reportData[device];
      const resultArray =
        deviceData.lighthouseResult?.audits['screenshot-thumbnails']?.details?.items;
      return resultArray ?? [];
    },
    [reportData]
  );

  return (
    <div>
      <TabContext value={selectedTab}>
        <TabList
          onChange={(event, value) => {
            setSelectedTab(value);
          }}
          aria-label="Select Site Version"
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
          }}
        >
          <Tab
            icon={<PhoneIphoneOutlined />}
            iconPosition="start"
            label={DEVICES.MOBILE}
            value={DEVICES.MOBILE}
            style={{
              minHeight: '50px',
            }}
          />
          <Tab
            icon={<DesktopMacOutlined />}
            iconPosition="start"
            label={DEVICES.DESKTOP}
            value={DEVICES.DESKTOP}
          />
        </TabList>
        {_.values(DEVICES).map((tabName) => (
          <TabPanel value={tabName} style={{ padding: '0px' }}>
            <ChartContainer requestUrl={''} report={reportData[tabName]} />
            <br />
            <ScreenshotContainer
              thumbnails={getThumbnailList(tabName)}
              mobile={selectedTab === DEVICES.MOBILE}
              speedIndex={
                reportData[tabName] ? reportData[tabName][TOP_FIVE_ENUM.SPEED_INDEX] : null
              }
            />
            <br />
            <MetricsContainer key={url} reportData={reportData[tabName]} />
            <br />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default ReportConatiner;
