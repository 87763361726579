import { LinearProgress, linearProgressClasses, styled } from '@mui/material';
import styles from './style.module.css';

type TProps = {
  value: number;
  barColor?: string;
  baseColor?: string;
  height?: number;
  borderRadius?: number;
};

const RectangleProgressBar = (props: TProps) => {
  const {
    value,
    baseColor = '#c1e0c055',
    barColor = '#5bbb58',
    height = 5,
    borderRadius = 5,
  } = props;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height,
    borderRadius,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: baseColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius,
      backgroundColor: barColor,
    },
  }));
  return (
    <div className={styles.container}>
      <BorderLinearProgress variant="determinate" value={value} />
    </div>
  );
};

export default RectangleProgressBar;
