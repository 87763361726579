import { useState } from 'react';
import SearchSection from './SearchSection';
import styles from './style.module.css';
import HistoryReportContainer from './HistoryReportContainer';
import { useNavigate } from 'react-router-dom';

const SiteHistorySection = () => {
  const [selectedHistory, setSelectedHistory] = useState(null);
  const navigate = useNavigate();

  const setTaskGroupIdParam = (data: any) => {
    if (data) {
      setSelectedHistory(data);
      const pathUrl = '../site-history/' + data.taskStatus.taskGroupId;
      navigate(pathUrl);
    }
  };

  return (
    <div className={styles.search__history_section}>
      <div className={styles.search__container}>
        <SearchSection onSelect={setTaskGroupIdParam} selected={selectedHistory} />
      </div>
      <div className={styles.report__container}>
        <HistoryReportContainer data={selectedHistory} />
      </div>
    </div>
  );
};

export default SiteHistorySection;
