import { memo, useCallback, useState } from 'react';
import { Tab } from '@mui/material';
import ChartContainer from '../../common/ChartContainer';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DesktopMacOutlined, PhoneIphoneOutlined } from '@mui/icons-material';
import { getPerformanceScoreObject, getPerformanceSummary, getSummary } from './SummaryReportUtils';
import _ from 'lodash';
import SimpleTable from './SimpleTable';
import { auditType } from '../../BasicReport/types';
import { formatNumber } from '../../../modules/Utils/utils';
import { Metric } from '../../common/Metric/Metric';
import styles from './style.module.css';
import UrlList from '../../common/URLList';

type TProps = {
  data: any;
};
export const SummeryReport = memo((props: TProps): any => {
  const [selectedTab, setSelectedTab] = useState('mobile');
  const { data } = props;

  const getPerformanceReport = useCallback(
    (device: string) => {
      const performaceObject = getPerformanceSummary(data, device);
      const performaceScoreObject = getPerformanceScoreObject(data, device);
      const performanceScore = performaceScoreObject ? performaceScoreObject.mean : 0;
      const report = {
        ...performaceObject,
        performanceScore,
      };
      return report;
    },
    [data]
  );

  const getDeviceSummary = useCallback(
    (device: string) => {
      const summary = getSummary(data, device);
      return summary;
    },
    [data]
  );

  const getTopAudits = useCallback(
    (device: string) => {
      const tempAudits: any[] = _.values(getSummary(data, device));
      const audits = (tempAudits as auditType[])
        .filter((audit: any) => {
          return audit.mean > 0;
        })
        .sort((a, b) => a.score - b.score);
      return audits.slice(0, 5);
    },
    [data]
  );

  const getDisplayValue = useCallback((numericValue: number, numericType: string) => {
    if (numericType === 'millisecond') {
      return `${formatNumber(numericValue / 1000)} s`;
    }
    if (numericType === 'byte') {
      return `${formatNumber(numericValue / 1000)} KB`;
    }
    return formatNumber(numericValue);
  }, []);

  return (
    <div>
      <br />
      <TabContext value={selectedTab}>
        <TabList
          onChange={(event, value) => {
            setSelectedTab(value);
          }}
          aria-label="Select Site Version"
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
          }}
        >
          <Tab
            icon={<PhoneIphoneOutlined />}
            iconPosition="start"
            label=""
            value="mobile"
            sx={{ background: '#fff' }}
          />
          <Tab icon={<DesktopMacOutlined />} iconPosition="start" label="" value="desktop" />
        </TabList>
        {['mobile', 'desktop'].map((tabName) => (
          <TabPanel value={tabName} style={{ padding: '0px' }}>
            <ChartContainer requestUrl={''} report={getPerformanceReport(tabName)} />
            <UrlList urlList={data.advancedReport.report} />
            <div className={styles.metrcs__main_container}>
              <div>
                <div className={'title__text'}> metrics</div>
                <div className={styles.metric__container}>
                  {getTopAudits(tabName).map((audit: any) => {
                    return (
                      <Metric
                        key={audit.key}
                        value={audit.mean * 100}
                        title={audit.key.split('-').join(' ').toLocaleUpperCase()}
                        displayValue={getDisplayValue(audit.mean, audit.numericUnit)}
                      />
                    );
                  })}
                </div>
              </div>
              <SimpleTable summary={getDeviceSummary(tabName)} />
            </div>
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
});
