import { useEffect } from 'react';
import { setInitialAuth } from '../redux/features/Auth/authSlice';
import { useDispatch } from 'react-redux';

type TProps = {
  component: React.ReactNode;
};
const AuthWrapper = (props: TProps) => {
  const { component } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitialAuth());
  }, []);
  return <>{component}</>;
};

export default AuthWrapper;
