import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { EmojiObjectsOutlined } from '@mui/icons-material';
import { LoadingTipsProps } from './types';

import styles from './loadingtips.module.css';
import { LOADING_TIPS } from '../../modules/Utils/constants';

export const LoadingTips = memo(({ status }: LoadingTipsProps) => {
  const intervalRef = useRef<number | undefined>(undefined);
  const pRef = useRef<HTMLSpanElement>(null!);
  const [tipIndex, setTipIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTipIndex((previousIndex) => (previousIndex + 1) % LOADING_TIPS.length);
    }, 6 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const stopTimer = useCallback(() => {
    window.clearTimeout(intervalRef.current);
  }, []);

  useEffect(() => {
    if (status === -1) {
      stopTimer();
      return;
    }

    intervalRef.current = window.setInterval(() => {
      if (!pRef.current) return;
      let loadingText = pRef.current.innerText.trim();
      if (loadingText === '.') {
        loadingText = '..';
      } else if (loadingText === '..') {
        loadingText = '...';
      } else {
        loadingText = '.';
      }
      pRef.current.innerText = ` ${loadingText}`;
    }, 1000);
    return () => {
      stopTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className={styles.tips__container}>
      <EmojiObjectsOutlined className={styles.icon} />
      <p key={tipIndex}>{LOADING_TIPS[tipIndex]}</p>
      {status === 0 ? (
        <p className={styles.icon}>
          Analyzing Website <span ref={pRef}> . </span>
        </p>
      ) : null}
    </div>
  );
});
