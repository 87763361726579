import { useEffect, useRef, useState } from 'react';
import styles from './style.module.css';

type TProps = {
  columns: string[];
  rowData: any;
  details: React.ReactElement;
};
const TableRow = (props: TProps) => {
  const { columns, rowData, details } = props;
  const rowRef: any = useRef(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const maxHeight = expanded ? '400px' : '0px';
    if (rowRef && rowRef.current) {
      rowRef.current.style.maxHeight = maxHeight;
      rowRef.current.style.height = 'auto';
    }
  }, [expanded]);
  return (
    <div className={expanded ? styles.expanded_container : ''}>
      <div className={`${styles.table_row} ${expanded ? styles.expanded_row : ''}`} onClick={() => setExpanded(!expanded)}>
        {columns.map((col) => {
          return <div className={styles.table_col}>{rowData[col] ?? '-'}</div>;
        })}
      </div>
      <div ref={rowRef} className={styles.details_section}>
        <div className={styles.jsx_container}>{details}</div>
      </div>
    </div>
  );
};

export default TableRow;
