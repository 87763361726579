import { LoaderProps } from './types';
import styles from './loader.module.css';
import { useEffect, useState } from 'react';
import { LOADING_TIPS } from '../../../modules/Utils/constants';

export const Loader = ({ loaderText }: LoaderProps) => {
  const [tipIndex, setTipIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTipIndex((previousIndex) => (previousIndex + 1) % LOADING_TIPS.length);
    }, 6 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div className={styles.loader}>
        <div className={styles.bar1}></div>
        <div className={styles.bar2}></div>
        <div className={styles.bar3}></div>
        <div className={styles.bar4}></div>
        <div className={styles.bar5}></div>
        <div className={styles.bar6}></div>
        <div className={styles.bar7}></div>
        <div className={styles.bar8}></div>
        <div className={styles.bar9}></div>
        <div className={styles.bar10}></div>
        <div className={styles.bar11}></div>
        <div className={styles.bar12}></div>
      </div>
      {loaderText ? <p className={styles.loaderText}> {loaderText} </p> : null}
      <p key={tipIndex}>{LOADING_TIPS[tipIndex]}</p>
    </div>
  );
};
