import styles from './advancedreport.module.css';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

type TProps = {
  reason: string;
};
const APIErrorComponent = (props: TProps) => {
  const { reason } = props;
  return (
    <div className={styles.api_error_container}>
      <div className={styles.api_error_main_head}>
        <ReportProblemOutlinedIcon className={styles.api_error_main_head_icon} />
        <div className={styles.api_error_main_head_msg}>
          API report is not available due to error
        </div>
      </div>
      <div>
        <span className={styles.api_error_label}>Reason: </span>
        <span className={styles.api_error_message}>{reason}</span>
      </div>
    </div>
  );
};

export default APIErrorComponent;
