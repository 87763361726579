import { Chip, Tooltip } from '@mui/material';
import { getProgressBarColorObject } from '../../../modules/Utils/utils';
import FullDonut from '../FullDonut';
import styles from './style.module.css';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type TProps = {
  data: any;
  onSelect: Function;
  isSelected: Boolean;
  errorMessage?: String | null;
};
const Card = (props: TProps) => {
  const { data, onSelect, isSelected, errorMessage } = props;
  const { baseColor, barColor } = getProgressBarColorObject(data.performanceScore);
  return (
    <div
      className={`${styles.history__card} ${isSelected ? styles.selected : ''}`}
      onClick={() => onSelect(data)}
    >
      <div className={styles.history__card_chart}>
        <FullDonut value={data.performanceScore * 100} baseColor={baseColor} barColor={barColor} />
      </div>
      <div className={styles.history__card_text_container}>
        <div className={styles.history__card_url}>{data.url}</div>
        <div className={styles.history__card_date}>{new Date(data.createdAt).toLocaleString()}</div>
      </div>
      {errorMessage && (
        <div className={styles.error__tooltip_container}>
          <Tooltip title={errorMessage}>
            <div>
              <ErrorOutlineIcon style={{ color: '#b80303', fontSize: '14px' }} />
              <div>Error</div>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Card;
