import { useCallback, useState } from 'react';
import { AUDIT_ERROR_STATUS } from '../../../modules/constants';
import UrlSelector from './UrlSelector';
import {
  getEndpointList,
  getFormattedReportTableData,
  getHttpSuccessData,
  getResponseTimeData,
} from '../APIUtils';
import APIReport from '../APIReport';
import ErrorReport from '../../common/ErrorReport';
import Loader from '../../common/Loader';
import styles from '../advancedreport.module.css';
import APIErrorComponent from '../APIErrorComponent';
import { getErrorMessage } from '../../../modules/Utils/utils';

type TProps = {
  data: any;
  reportStatus?: any;
};
const APIDetails = (props: TProps) => {
  const { data, reportStatus } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getReportData = () => {
    return data?.report;
  };
  const endpointList = useCallback(() => {
    const reportData = getReportData();
    const endpoints = getEndpointList(reportData);
    return endpoints;
  }, [data?.report]);

  const getReportTabledata = useCallback(() => {
    const reportData = getReportData();
    const endpoint = getEndpointList(reportData)[selectedIndex];
    return getFormattedReportTableData(reportData, endpoint);
  }, [data?.report, selectedIndex]);

  const getHttpSuccessReportData = useCallback(() => {
    const reportData = getReportData();
    const endpoint = getEndpointList(reportData)[selectedIndex];
    return getHttpSuccessData(reportData, endpoint);
  }, [data?.report, selectedIndex]);

  const getResponseTimereportData = useCallback(() => {
    const reportData = getReportData();
    const endpoint = getEndpointList(reportData)[selectedIndex];
    return getResponseTimeData(reportData, endpoint);
  }, [data?.report, selectedIndex]);

  if (data === AUDIT_ERROR_STATUS) {
    return (
      <div className={`${styles.loader__container} ${styles.advanced__reports__container}`}>
        <APIErrorComponent reason={getErrorMessage(reportStatus?.executionNote)} />
      </div>
    );
  } else if (data === null) {
    return (
      <div className={`${styles.loader__container} ${styles.advanced__reports__container}`}>
        <Loader loaderText="API Report may take a while" />
      </div>
    );
  }

  return (
    <div>
      <br />
      <UrlSelector
        selectedIndex={selectedIndex}
        onChangeHandler={setSelectedIndex}
        urlList={endpointList()}
      />
      <br />
      <APIReport
        reportTableData={getReportTabledata()}
        responseTimeChart={getResponseTimereportData()}
        httpSuccessData={getHttpSuccessReportData()}
      />
    </div>
  );
};

export default APIDetails;
