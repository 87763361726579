import styles from './style.module.css';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type TProps = {
  errorMessage: string;
};
const ErrorMessage = (props: TProps) => {
  const { errorMessage } = props;
  return (
    <div className={styles.error__msg_container}>
      <ErrorOutlineIcon className={styles.error__msg_icon} />
      <div className={styles.error__msg_label}>{errorMessage}</div>
    </div>
  );
};

export default ErrorMessage;
