import { memo, useMemo, useState } from 'react';
import BasicReport from '../BasicReport';
import AdvancedReports from '../AdvancedReports';
import { useAppSelector } from '../../redux/app/hooks';
import { AUDIT_ERROR_STATUS, REPORTS, TASK_NOT_INITIATED } from '../../modules/constants';
import styles from './styles.module.css';
import VerticalLoader from '../common/VerticalLoader';
import AdvancedIcon from '../../assets/images/advanced-tabs.png';
import BasicIcon from '../../assets/images/basic-report.png';
import HeaderSection from './HeaderSection';

const Reports = memo(() => {
  const [isBasicReportSelected, setIsBasicReportSelected] = useState(true);

  const data = useAppSelector((state) => ({
    advancedReport: state.reports[REPORTS.ADVANCED],
  }));

  const advancedData = useAppSelector((state) => ({
    userEmail: state.auth.userEmail,
    advancedReport: state.reports[REPORTS.ADVANCED],
    apiReport: state.reports[REPORTS.BACKEND_API],
    createdAt: state.reports.createdAt,
    url: state.reports.url,
    taskId: state.reports.taskId,
    advancedReportStatus: state.reportStatus[REPORTS.ADVANCED],
    apiReportStatus: state.reportStatus[REPORTS.BACKEND_API],
  }));

  const basicReportData = useAppSelector((state) => ({
    data: state.reports[REPORTS.BASIC],
    reportStatus: state.reportStatus[REPORTS.BASIC],
  }));

  const advancedReportData = useMemo(() => {
    if (!data.advancedReport) {
      return null;
    }
    if (data.advancedReport === AUDIT_ERROR_STATUS || data.advancedReport === TASK_NOT_INITIATED) {
      return data.advancedReport;
    }
    const advancedReport = data.advancedReport.report.reduce(
      (map: { [key: string]: any }, obj: { url: string }) => {
        map[obj.url] = obj;
        return map;
      },
      {}
    );

    return advancedReport;
  }, [data.advancedReport]);

  return (
    <>
      {advancedData && advancedReportData !== TASK_NOT_INITIATED && (
        <HeaderSection
          advancedData={advancedData}
          showDownload={advancedReportData && advancedData?.apiReport !== null ? true : false}
        />
      )}
      <div className={styles.report__title__container}>
        <div
          className={`${styles.report__title__div} ${
            isBasicReportSelected ? styles.report__title__div_selected : ''
          }`}
          onClick={() => setIsBasicReportSelected(true)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              setIsBasicReportSelected(true);
            }
          }}
        >
          <span>
            <img src={BasicIcon} height={18} alt="basic" />
          </span>
          <span className={isBasicReportSelected ? styles.report__selected : styles.report__text}>
            Basic Report
          </span>
        </div>
        {advancedReportData !== TASK_NOT_INITIATED && (
          <div
            className={`${styles.report__title__div} ${
              !isBasicReportSelected ? styles.report__title__div_selected : ''
            }`}
            onClick={() => setIsBasicReportSelected(false)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                setIsBasicReportSelected(false);
              }
            }}
          >
            <div className={styles.advanced__tab_conatiner}>
              <span>
                <img src={AdvancedIcon} height={30} alt="advanced" />
              </span>
              <span
                className={!isBasicReportSelected ? styles.report__selected : styles.report__text}
              >
                Advanced Report
              </span>
              {advancedReportData !== AUDIT_ERROR_STATUS && !advancedReportData && (
                <VerticalLoader />
              )}
            </div>
          </div>
        )}
      </div>

      <div className={styles.report__details}>
        {isBasicReportSelected ? (
          <BasicReport
            basicReportData={basicReportData.data}
            reportStatus={basicReportData.reportStatus}
          />
        ) : (
          <AdvancedReports data={advancedData} />
        )}
      </div>
    </>
  );
});

export default Reports;
