import { useState } from 'react';
import ListSelector from '../../common/ListSelector';
import styles from './style.module.css';
import ErrorMessage from './ErrorMessage';

type TProps = {
  urls: string[];
  selectedindexList: number[];
  onSelectionChange: Function;
};
const UIUrlSelector = (props: TProps) => {
  const { urls, selectedindexList, onSelectionChange } = props;
  const [error, setError] = useState('');

  const onSelect = (updatedIndexList: number[]) => {
    setError('');
    if (updatedIndexList.length > 5) {
      setError('Maximum of 5 url selection possible');
    } else {
      onSelectionChange(updatedIndexList);
    }
  };

  return (
    <div>
      <div className={styles.wizard__title}>Choose UI Urls to be analysed</div>
      <div className={styles.wizard__info}>Maximum number of urls that can be analysed is 5.</div>
      {error && <ErrorMessage errorMessage={error} />}
      {urls.length ? (
        <ListSelector
          labelList={urls}
          selectedIndexList={selectedindexList}
          onSelectionChange={onSelect}
          labelKey="url"
        />
      ) : (
        <div className={styles.wizard_empty_list_msg}>No urls found to analyse</div>
      )}
    </div>
  );
};

export default UIUrlSelector;
