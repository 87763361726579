import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from './style.module.css';
import { Bolt } from '@mui/icons-material';
import { fetchAdditionalRoutes, fetchRoutesAndApis } from '../../../modules/Utils/reportsUtil';
import Loader from '../../common/Loader';
import ErrorComponent from './ErrorComponent';
import Wizard from './Wizard';
import { Box, CircularProgress, OutlinedInput } from '@mui/material';

type TProps = {
  url: string;
  uid: string;
  setPageUrl: Function;
};

const SelectionModal = (props: TProps) => {
  const { url, uid, setPageUrl } = props;
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');
  const [host, setHost] = useState('');
  const handleOpen = () => setOpen(true);
  const [routes, setRoutes] = useState([]);
  const [urlList, setUrlList] = useState([]);
  const [basicReport, setBasicReport] = useState();
  const [selectedUrlIndexes, setSelectedUrlIndexes] = useState<number[]>([]);
  const [selectedRouteIndexes, setSelectedRoutesIndexes] = useState<number[]>([]);
  const [urlError, setUrlError] = useState('');
  const [isAdditionalUrlsLoading, setAdditionalUrlsLoading] = useState(false);
  const [additionalError, setAdditionalError] = useState('');
  const [additionalUrls, setAdditionalUrls] = useState<any[]>([]);
  const [showGenerateAdvancedSection, setShowGenerateAdvancedsection] = useState<boolean>(false);

  useEffect(() => {
    setUrlError('');
  }, [url]);

  const handleClose = () => {
    setShowGenerateAdvancedsection(false);
    setOpen(false);
  };

  const updateSelectedUrls = (urls: any[], routeList: any[]) => {
    const urlIndexList: number[] = urls.map((item: any, index: number) => index);
    setSelectedUrlIndexes(urlIndexList);
    const routesIndexList = routeList.map((item: any, index: number) => index);
    setSelectedRoutesIndexes(routesIndexList);
  };

  const updateRoutesAndUrls = (data: any) => {
    setRoutes(data?.routes?.requests);
    setUrlList(data?.urls?.selected);
    setHost(url);
    setBasicReport(data.report);
    updateSelectedUrls(data?.urls?.selected, data?.routes?.requests);
  };
  const isValidUrl = () => {
    if (
      /^(https:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
        url
      )
    ) {
      return true;
    }
    return false;
  };

  const loadCheckDetails = async () => {
    setUrlError('');
    if (isValidUrl()) {
      try {
        setLoader(true);
        setError('');
        setShowGenerateAdvancedsection(false);
        const response = await fetchRoutesAndApis(url);
        updateRoutesAndUrls(response);
        setShowGenerateAdvancedsection(true);
        loadAdditionalUrls(response?.urls?.selected);
      } catch (e: any) {
        if (e?.response?.data) {
          setError(e.response.data.error ?? JSON.stringify(e));
        } else {
          setError(e.message ?? JSON.stringify(e));
        }
        setUrlError(e);
      } finally {
        setLoader(false);
      }
    } else {
      setUrlError('Invalid page url format.');
    }
  };

  const loadAdditionalUrls = async (selectedUrlItem: any[]) => {
    const context = selectedUrlItem.map((item) => item.url);
    const request = {
      context,
      url: host,
      uid: uid,
    };
    try {
      setAdditionalError('');
      setAdditionalUrlsLoading(true);
      const responseData = await fetchAdditionalRoutes(request);
      setAdditionalUrls(responseData.success);
    } catch (e: any) {
      if (e?.response?.data) {
        setAdditionalError(e.response.data.error ?? JSON.stringify(e));
      } else {
        setAdditionalError(e.message ?? JSON.stringify(e));
      }
    } finally {
      setAdditionalUrlsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.inputbox__container}>
        <OutlinedInput
          value={url}
          placeholder="Enter your site url. Ex: https://www.quince.com"
          onChange={(e) => {
            setPageUrl(e.target.value);
          }}
          className={styles.inputbox}
          aria-describedby="analyze-url"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              loadCheckDetails();
            }
          }}
          error={urlError ? true : false}
        />
        {urlError ? <div className={styles.url_error_box}>{urlError}</div> : null}
        <Button variant="contained" className={styles.button} onClick={loadCheckDetails}>
          <Bolt />
          Analyze
        </Button>
      </div>
      {showGenerateAdvancedSection && (
        <div className={styles.generate_advanced_section}>
          <div className={styles.generate_advanced_message}>
            Do you want to generate advanced report?
          </div>
          <Box sx={{ position: 'relative' }}>
            <Button
              variant="contained"
              onClick={handleOpen}
              className={`${styles.generate_advanced_button} ${
                isAdditionalUrlsLoading ? styles.disabled_button : ''
              }`}
              disabled={isAdditionalUrlsLoading}
            >
              Generate Advance Report
            </Button>
            {isAdditionalUrlsLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: '#000',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.modal__container}>
          {loader ? (
            <div className={styles.loader__container}>
              <Loader loaderText="Fetching routes and apis for analysis" />
            </div>
          ) : error ? (
            <ErrorComponent errorMessage={error} onClose={handleClose} />
          ) : (
            <Wizard
              routes={routes}
              urls={urlList}
              onClose={handleClose}
              host={host}
              uid={uid}
              basicReport={basicReport}
              selectedUrlIndexes={selectedUrlIndexes}
              setSelectedUrlIndexes={setSelectedUrlIndexes}
              selectedRouteIndexes={selectedRouteIndexes}
              setSelectedRoutesIndexes={setSelectedRoutesIndexes}
              additionalError={additionalError}
              additionalUrls={additionalUrls}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default SelectionModal;
