import Header from '../Header';
import Section from '../Section';

const Layout = () => {
  return (
    <>
      <Header />
      <Section />
    </>
  );
};

export default Layout;
