import { memo, useEffect } from 'react';

import { signInWithPopup } from 'firebase/auth';
import { Button, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { auth, provider } from '../../modules/Utils/firebaseConfig';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { setAuth } from '../../redux/features/Auth/authSlice';
import { displaySnackBar } from '../../modules/Utils/showSnackBar';
import { subscribe } from '../../modules/Utils/reportsUtil';
import { TRIGGER_SUBSCRIBE } from '../../modules/constants';

import GoogleImage from '../../assets/images/google.png';
import icon from '../../assets/images/roadrunnr.png';

import styles from './login.module.css';

export const Login = memo(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const authState = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (authState.userEmail) {
      if (location.state && location.state.previousUrl) {
        navigate(location.state.previousUrl);
      } else {
        navigate('/');
      }
    }
  }, [authState.userEmail]);

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((res) => {
        const { user } = res;
        dispatch(
          setAuth({
            userEmail: user.email!,
            userName: user.displayName!,
            uid: user.uid!,
          })
        );
        if (location.state && location.state.previousUrl) {
          navigate(location.state.previousUrl);
        } else {
          navigate('/');
        }
        if (state && state[TRIGGER_SUBSCRIBE]) {
          subscribe({
            userEmail: user.email!,
            userName: user.displayName!,
            uid: user.uid!,
          });
        }
      })
      .catch((err) => {
        if (err.message.includes('popup-closed-by-user')) {
          return;
        }
        displaySnackBar({
          severity: 'error',
          description: 'Unable to login at the moment. Please try again.',
        });
        console.error(err);
      });
  };

  return (
    <div className={styles.login__container}>
      <Paper elevation={5} className={styles.paper__container}>
        <div>
          <img src={icon} alt="Roadrunnr" />
        </div>
        <div className={styles.login__text}> Log in </div>
        <Button variant="outlined" className={styles.login__button} onClick={signInWithGoogle}>
          <img src={GoogleImage} alt="google" width={35} height={25} />
          Login with Google
        </Button>
        <p className={styles.copyright__text}>Copyright @91social.com</p>
      </Paper>
    </div>
  );
});
