import Shopify from '../../assets/images/Shopify-Logo.png';
import Custom from '../../assets/images/custom-stack.png';
import Magento from '../../assets/images/magento.png';
import BigCommerce from '../../assets/images/bigcommerce.png';
import OsCommerce from '../../assets/images/OsCommerce-logo.png';
import WooCommerce from '../../assets/images/woocommerce-1.png';

export const getStackIcon = (stack: string) => {
  switch (stack) {
    case 'Shopify':
      return Shopify;
    case 'Magento':
      return Magento;
    case 'BigCommerce':
      return BigCommerce;
    case 'OsCommerce':
      return OsCommerce;
    case 'WooCommerce':
      return WooCommerce;
    default:
      return Custom;
  }
};
