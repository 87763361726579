import { axiosInstance } from '../../Axios';
import { markStatusAsDone, setTaskIds, setUrl } from '../../redux/features/Reports/reportsSlice';
import {
  getTaskGroupEndpoint,
  getReportDataEndpoint,
  REPORTS,
  getSiteHistoryEndpoint,
  AUDIT_ERROR_STATUS,
  getUserHistoryEndpoint,
  getSiteHistoryEndpointWix,
  getReportStatusEndpoint,
} from '../constants';
import { getReportStatus, showErrorOnScreen } from './reportsUtil';
import store from '../../redux/app/store';
import _ from 'lodash';
import { setStatusResponse } from '../../redux/features/Reports/reportStatusSlice';

export const getSiteHistoryList = async () => {
  const endpoint = getSiteHistoryEndpoint();
  const response = await axiosInstance.get(endpoint);
  return response;
};

export const getSiteHistoryListWix = async () => {
  const endpoint = getSiteHistoryEndpointWix();
  const response = await axiosInstance.get(endpoint);
  return response;
};

export const getUserHistoryList = async (uid: string) => {
  const endpoint = getUserHistoryEndpoint(uid);
  const response = await axiosInstance.get(endpoint);
  return response;
};

export const getTaskGroup = async (taskGroupId: any) => {
  const endpoint = getTaskGroupEndpoint(taskGroupId);
  try {
    const response = await axiosInstance.get(endpoint);
    return response.data;
  } catch (e) {
    showErrorOnScreen();
    return null;
  }
};

export const getReportStatusResponse = async (id: string, reportType: string) => {
  const endpoint = getReportStatusEndpoint(id);
  const response = await axiosInstance.get(endpoint);
  store.dispatch(
    setStatusResponse({
      reportStatus: response.data ? response.data.status : null,
      reportType,
    })
  );
  return response.data ? response.data.status : {};
};

export const getReportAPI = async (id: string) => {
  const endpoint = getReportDataEndpoint(id);
  return axiosInstance.get(endpoint);
};

export const getSiteHistoryReport = async (reportIdObject: any) => {
  const basicReportPromise = getReportAPI(reportIdObject[REPORTS.BASIC].id);
  const advancedReportPromise = getReportAPI(reportIdObject[REPORTS.ADVANCED].id);
  const apiReportPromise = getReportAPI(reportIdObject[REPORTS.BACKEND_API].id);
  const results = await Promise.allSettled([
    basicReportPromise,
    advancedReportPromise,
    apiReportPromise,
  ]);
  return {
    [REPORTS.BASIC]:
      results[0].status === 'fulfilled' ? results[0].value?.data : AUDIT_ERROR_STATUS,
    [REPORTS.ADVANCED]:
      results[1].status === 'fulfilled' ? results[1].value?.data : AUDIT_ERROR_STATUS,
    [REPORTS.BACKEND_API]:
      results[2].status === 'fulfilled' ? results[2].value?.data : AUDIT_ERROR_STATUS,
  };
};

export const loadHistory = async (historyData: any) => {
  store.dispatch(setUrl(historyData.url));
  try {
    const basicReportIdObject = _.find(historyData.taskList, { taskType: 'SIMPLE' });
    const advancedReportIdObject = _.find(historyData.taskList, { taskType: 'ADVANCED' });
    const backendReportIdObject = _.find(historyData.taskList, { taskType: 'BACKEND' });

    const taskIdObject = {
      [REPORTS.BASIC]: basicReportIdObject?.id,
      [REPORTS.ADVANCED]: advancedReportIdObject?.id,
      [REPORTS.BACKEND_API]: backendReportIdObject?.id,
    };
    store.dispatch(setTaskIds(taskIdObject));
    getReportStatus(taskIdObject[REPORTS.BASIC], REPORTS.BASIC);
    getReportStatus(taskIdObject[REPORTS.ADVANCED], REPORTS.ADVANCED);
    getReportStatus(taskIdObject[REPORTS.BACKEND_API], REPORTS.BACKEND_API);
  } catch (err) {
    showErrorOnScreen();
    store.dispatch(markStatusAsDone());
    console.error(err);
  }
};

export const getPdfReport = async (reportIdObject: any) => {
  await getReportStatusResponse(reportIdObject[REPORTS.BASIC], REPORTS.BASIC);
  await getReportStatusResponse(reportIdObject[REPORTS.BACKEND_API], REPORTS.BACKEND_API);
  const basicReportPromise = getReportAPI(reportIdObject[REPORTS.BASIC]);
  const apiReportPromise = getReportAPI(reportIdObject[REPORTS.BACKEND_API]);
  const results = await Promise.allSettled([basicReportPromise, apiReportPromise]);
  return {
    [REPORTS.BASIC]:
      results[0].status === 'fulfilled' ? results[0].value?.data : AUDIT_ERROR_STATUS,
    [REPORTS.BACKEND_API]:
      results[1].status === 'fulfilled' ? results[1].value?.data : AUDIT_ERROR_STATUS,
  };
};
