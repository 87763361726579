import { ErrorInfo, PureComponent } from 'react';

import { ErrorBoundaryProps, ErrorBoundaryState } from './types';

import ErrorBoundaryImage from '../../assets/images/ErrorBoundary.png';
import styles from './errorboundary.module.css';

export class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className={styles.errorboundary__container}>
          <img src={ErrorBoundaryImage} alt="Error Boundary" width="40%" height="40%" />
          <h3 className={styles.h3__text}> Something went wrong </h3>
          <p className={styles.p__text}> Please refresh the page or try again later </p>
        </div>
      );
    }
    return <>{this.props.children}</>;
  }
}
