import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { METRIC_REF } from './MetricReferenceUtils';
import styles from './style.module.css';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import metricIcon from '../../../assets/images/metric-section.png';

const MetricReference = () => {
  return (
    <div className={styles.metric_ref_container}>
      <div>
        <div className={'title__text'}>
          <EqualizerIcon className={styles.metric_ref_equalizer} />
          Performance Calculation
        </div>
        <div className={styles.metric_ref_content_main}>
          The Performance score is a weighted average of the metric scores. Naturally, more heavily
          weighted metrics have a bigger effect on your overall Performance score
        </div>
      </div>
      <TableContainer className={styles.metric_ref_table_container}>
        <Table className={styles.metric_ref_table}>
          {/* <TableRow>
            <TableCell colSpan={2}>
              
            </TableCell>
          </TableRow> */}
          <TableRow className={styles.metric_ref_head_row}>
            <TableCell className={styles.metric_ref_head_cell_1}>
              {' '}
              <img src={metricIcon} height={20} alt="metrics" />{' '}
              <div className={styles.metric_ref_head_cell_1_label}>Metric</div>
            </TableCell>
            <TableCell className={styles.metric_ref_head_cell_2}>
              <ScaleOutlinedIcon className={styles.metric_ref_scale} />
              Weightage
            </TableCell>
          </TableRow>
          {METRIC_REF.map((item: any) => {
            return (
              <TableRow>
                <TableCell className={styles.metric_ref_col_1}>
                  <div className={styles.metric_ref_name}>{item.name}</div>
                  <div className={styles.metric_ref_content}>{item.description}</div>
                </TableCell>
                <TableCell className={styles.metric_ref_col_2}>{item.weight}</TableCell>
              </TableRow>
            );
          })}
        </Table>
      </TableContainer>
    </div>
  );
};

export default MetricReference;
