import Header from '../Header';
import SiteHistorySection from '../SiteHistorySection';

const SiteHistoryLayout = () => {
  return (
    <>
      <Header />
      <SiteHistorySection />
    </>
  );
};

export default SiteHistoryLayout;
