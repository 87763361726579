import styles from './style.module.css';
import { useRef } from 'react';
import TableRow from './TableRow';
import _ from 'lodash';

type TProps = {
  columns: string[];
  scoreColumn: string;
  data: any[];
};

const ExpandableTable = (props: TProps) => {
  const { columns, scoreColumn, data = [] } = props;
  const tableRef = useRef(null);

  const getEmptyComponent = () => {
    return (
      <div className={styles.empty_conatiner}>
        <h1>No Data Available</h1>
      </div>
    );
  };
  return (
    <div ref={tableRef} className={styles.table_container}>
      <div className={styles.table_row_head}>
        {columns.map((col) => {
          return <div className={styles.table_col_head}>{_.startCase(col)}</div>;
        })}
      </div>
      <div>
        {data.length === 0
          ? getEmptyComponent()
          : data.map((row) => {
              return <TableRow columns={columns} rowData={row} details={row.details} />;
            })}
      </div>
    </div>
  );
};

export default ExpandableTable;
