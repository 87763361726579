import './style.css';

export const Loader = () => {
  return (
    <div className="vertical-loader">
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default Loader;
