import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import _ from 'lodash';
import styles from './style.module.css';
import { useCallback } from 'react';

type TProps = {
  reportData: any[];
};

const ReportTable = (props: TProps) => {
  const { reportData } = props;
  const getIndicatorColor = useCallback(
    (value: string) => {
      if (
        value.startsWith('4') ||
        value.startsWith('5') ||
        value.startsWith('errors.') ||
        value.startsWith('http.codes.4') ||
        value.startsWith('http.codes.5')
      ) {
        return styles.indicator__circle_red;
      } else if (value.startsWith('1') || value.startsWith('2') || value.startsWith('3')) {
        return styles.indicator__circle_green;
      }
      return styles.indicator__circle;
    },
    [reportData]
  );

  return (
    <div className={styles.report__table_main_container}>
      <p className="title__text_bold">API Metrics</p>
      <br />
      <TableContainer className={styles.r_table_container}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className={styles.report__table}>
          <TableHead>
            <TableRow className={styles.r_tabel_head_row}>
              <TableCell>{''}</TableCell>
              <TableCell colSpan={_.keys(reportData[0]).length - 1} className={styles.r_table_head}>
                {'DURATION'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.map((item: any, rowIndex: number) => {
              return (
                <TableRow key={rowIndex} className={rowIndex === 0 ? styles.r_table_row_0 : ''}>
                  {_.values(item).map((value: any, colIndex: number) => {
                    return (
                      <TableCell
                        key={colIndex}
                        className={`${styles.r_tabel_cell} ${
                          rowIndex === 0 ? styles.r_table_col_0 : ''
                        } ${colIndex === 0 ? styles.r_table_first_col : ''}`}
                      >
                        {colIndex === 0 ? (
                          <div className={styles.r_table_value_container}>
                            {rowIndex === 0 ? (
                              <div className={styles.indicator__circle_white}></div>
                            ) : (
                              <div className={getIndicatorColor(value)}></div>
                            )}
                            <div>{value}</div>
                          </div>
                        ) : (
                          <>{value}</>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReportTable;
