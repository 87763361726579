import ReportTable from './ReportTable';
import HttpSuccessChart from './HttpSuccess';
import LineChartConatiner from './LineChart';
import HttpCodeReference from '../../common/HttpCodeReference';

type TProps = {
  reportTableData: any[];
  responseTimeChart: any[];
  httpSuccessData: any[];
};

const APIReport = (props: TProps) => {
  const { reportTableData, responseTimeChart, httpSuccessData } = props;

  return (
    <div>
      <br />
      <HttpSuccessChart reportData={httpSuccessData} />
      <br />
      <LineChartConatiner reportData={responseTimeChart} />
      <br />
      <ReportTable reportData={reportTableData} />
      <br />
      <HttpCodeReference />
      <br />
    </div>
  );
};

export default APIReport;
