import { Provider } from 'react-redux';

import AppRoutes from './AppRoutes';
import SnackBarComponent from './components/Snackbar';
import store from './redux/app/store';

import './App.css';

function App() {
  return (
    <div className="constants">
      <Provider store={store}>
        <AppRoutes />
        <SnackBarComponent />
      </Provider>
    </div>
  );
}

export default App;
