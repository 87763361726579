import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type TProps = {
  selectedIndex: number;
  onChangeHandler: Function;
  urlList: any[];
};
const UrlSelector = (props: TProps) => {
  const { selectedIndex, onChangeHandler, urlList } = props;
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Select Url</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedIndex}
        label="Select Url"
        onChange={(event, child) => {
          onChangeHandler(event.target.value);
        }}
        className="url-selector"
      >
        {urlList.map((value, index) => {
          return (
            <MenuItem key={index} value={index}>
              {value.url}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default UrlSelector;
