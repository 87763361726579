import {
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
} from 'recharts';
import styles from './style.module.css';
import CustomTick from './CustomTick';
type TProps = {
  reportData: any[];
};
const LineChartConatiner = (props: TProps) => {
  const { reportData } = props;
  return (
    <section className={styles.graph__container}>
      <div className={styles.http__success_conatiner}>
        <p className="title__text_bold">API Latency Report</p>
        <br />
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={reportData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={'name'} tick={<CustomTick />} padding={{ left: 40 }} />
            <YAxis />
            <Tooltip />
            <Legend wrapperStyle={{ bottom: '-25px' }} />
            <Line
              type="monotone"
              dataKey="max"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              strokeWidth={1.5}
              animationDuration={500}
            />
            <Line
              type="monotone"
              dataKey="p99"
              stroke="#5e87ab"
              strokeWidth={1.5}
              animationDuration={500}
            />
            <Line
              type="monotone"
              dataKey="p95"
              stroke="#004f98"
              strokeWidth={1.5}
              animationDuration={500}
            />
            <Line
              type="monotone"
              dataKey="median"
              stroke="#0077b6"
              strokeWidth={1.5}
              animationDuration={500}
            />
            <Line
              type="monotone"
              dataKey="min"
              stroke="#40e0d0"
              strokeWidth={1.5}
              animationDuration={500}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.graph__description}>
        Report highlights api response time for requests sent at various loads starting from 1 per
        second to 20 per second. If the response times start increasing with load, it indicates a
        less than ideal experience for all users as more users start using the site.
      </div>
    </section>
  );
};
export default LineChartConatiner;
