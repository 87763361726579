import { memo, useCallback, useMemo, useState } from 'react';

import { Box, Button, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Loader from '../common/Loader';
import ErrorReport from '../common/ErrorReport';
import UIDetail from './UIDetail';
import { Recommendations } from './Recommendations/Recommendations';

import { ROUTES, AUDIT_ERROR_STATUS } from '../../modules/constants';

import styles from './advancedreport.module.css';
import APIReport from './APISummary';
import { SummeryReport } from './UISummery/SummeryReport';
import APIDetails from './APIDetails';
import VerticalLoader from '../common/VerticalLoader';
import { getErrorMessage } from '../../modules/Utils/utils';

enum TABS {
  'UI Summary',
  'UI Details',
  'API Summary',
  'API Details',
  Recommendations,
}

const TAB_NAMES = Object.keys(TABS).filter((v) => isNaN(Number(v)));

type TProps = {
  data: any;
};
export const AdvancedReports = memo((props: TProps) => {
  const [value, setValue] = useState(0);

  const navigate = useNavigate();
  const { data } = props;

  const advancedReportData = useMemo(() => {
    if (!data.advancedReport) {
      return null;
    }
    if (data.advancedReport === AUDIT_ERROR_STATUS) {
      return data.advancedReport;
    }
    const advancedReport = data.advancedReport.report.reduce(
      (map: { [key: string]: any }, obj: { url: string }) => {
        map[obj.url] = obj;
        return map;
      },
      {}
    );

    return advancedReport;
  }, [data.advancedReport]);

  const getLabelComponent = useCallback(
    (label: string) => {
      if (label === 'API Summary' || label === 'API Details') {
        return (
          <div className={styles.loader__tab_name}>
            <span>{label}</span>
            {data?.apiReport == null && <VerticalLoader />}
          </div>
        );
      }
      return label;
    },
    [data?.apiReport]
  );
  return (
    <>
      <div className={styles.advanced__reports__container}>
        {!data.userEmail ? (
          <>
            <div className={styles.please__login__text}>Please login to view advanced report</div>
            <Button
              className={styles.login__button}
              variant="contained"
              onClick={() =>
                navigate(ROUTES.loginRoute, { state: { previousUrl: window.location.pathname } })
              }
            >
              Log in
            </Button>
          </>
        ) : (
          <>
            <Box className={styles.box__container}>
              <Box>
                <Tabs
                  value={value}
                  onChange={(e, selectedTab: number) => setValue(selectedTab)}
                  sx={{
                    '& .MuiTabs-flexContainer': {
                      justifyContent: 'space-evenly',
                    },
                    '& button': {
                      color: 'var(--grey-text-color)',
                    },
                  }}
                >
                  {TAB_NAMES.map((tabName, index) => {
                    return (
                      <Tab
                        key={index}
                        id={`tab=${index}`}
                        tabIndex={0}
                        label={getLabelComponent(tabName)}
                      />
                    );
                  })}
                </Tabs>
              </Box>
            </Box>
            {advancedReportData === AUDIT_ERROR_STATUS ? (
              <div className={`${styles.loader__container} ${styles.advanced__reports__container}`}>
                <ErrorReport
                  message={
                    data?.advancedReportStatus
                      ? getErrorMessage(data.advancedReportStatus.executionNote)
                      : undefined
                  }
                />
              </div>
            ) : !advancedReportData ? (
              <div className={`${styles.loader__container} ${styles.advanced__reports__container}`}>
                <Loader loaderText="Analyzing Website" />
              </div>
            ) : (
              <>
                <div>
                  {value === TABS['UI Details'] && (
                    <UIDetail advancedReport={data.advancedReport.report} />
                  )}
                  {value === TABS['API Summary'] && (
                    <APIReport data={data.apiReport} reportStatus={data.apiReportStatus} />
                  )}
                </div>
                <div>{value === TABS.Recommendations && <Recommendations />} </div>
                <div>{value === TABS['UI Summary'] && <SummeryReport data={data} />}</div>
                <div>
                  {value === TABS['API Details'] && (
                    <APIDetails data={data.apiReport} reportStatus={data.apiReportStatus} />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
});
