import React from 'react';
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../redux/app/hooks';
import { hideSnackBar } from '../../redux/features/SnackBar/snackBarSlice';

export const SnackBarComponent = () => {
  const snackBarState = useAppSelector((state) => state.snackBar);
  const dispatch = useAppDispatch();

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideSnackBar());
  };

  return (
    <Snackbar
      open={snackBarState.show}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={snackBarState.severity}>
        {snackBarState.description}
      </Alert>
    </Snackbar>
  );
};
