import styles from './style.module.css';

import { HTTP_CODE_REF } from './HttpCodeReference';
import HttpCodeTable from './HttpCodeTable';

const HttpCodeReference = () => {
  return (
    <div className={styles.http_code_ref_conatainer}>
      <h2 className={styles.http_main_head}>Http code reference</h2>
      {HTTP_CODE_REF.map((item) => {
        return (
          <div className={styles.http__section}>
            <h3 className={styles.http_sub_head}>{item.name}</h3>
            <HttpCodeTable data={item.codes} color={item.color} />
          </div>
        );
      })}
    </div>
  );
};

export default HttpCodeReference;
