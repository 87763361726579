import {
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import _ from 'lodash';

type TProps = {
  value: number;
  label: string;
  size?: number;
  isBig?: boolean;
};
const RadialBarChartComponent = ({ value, label, size, isBig=false }: TProps) => {
  const data = [
    { name: 'L1', value: 0 },

    { name: 'L1', value: value },
  ];

  const getBackgroundColor = () => {
    if (value < 50) return '#f33';
    if (value < 90) return '#fa3';
    return '#0c6';
  };

  return (
    <div style={{ width: size ?? '100%', textAlign: 'center' }}>
      <CircularProgressbarWithChildren
        background
        value={value}
        strokeWidth={4}
        //   text={`${value}`}
        styles={{
          // Customize the root svg element
          root: {},
          // Customize the path, i.e. the "completed progress"
          path: {
            // Path color
            stroke: getBackgroundColor(),
            opacity: 1,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'round',
            // Customize transition animation
            transition: 'stroke-dashoffset 0.5s ease 0s',
            // Rotate the path
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
          },
          // Customize the circle behind the path, i.e. the "total progress"
          trail: {
            // Trail color
            stroke: getBackgroundColor(),
            opacity: 0,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            // Rotate the trail
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
          },
          // Customize the text
          text: {
            // Text color
            fill: getBackgroundColor(),
            // Text size
            fontSize: isBig ? '40px' : '16px',
          },
          // Customize background - only used when the `background` prop is true
          background: {
            fill: getBackgroundColor(),
            opacity: 0.1,
          },
        }}
      >
        <div
          style={{
            fontSize: isBig ? '40px' : '20px',
            fontWeight: 700,
            color: getBackgroundColor()
          }}
        >
          {Math.round(value)}
        </div>
      </CircularProgressbarWithChildren>
      <div
        style={{
          fontSize: isBig ? '20px' : '12px',
          minHeight: '30px',
          marginTop: '10px',
          color: '#000',
          fontWeight: 500,
          wordBreak: "break-word"
        }}
      >
        {_.startCase(label)}
      </div>
    </div>
  );
};
export default RadialBarChartComponent;
